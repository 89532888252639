import { PeriodicBooking, PeriodicBookingRepeatingType } from "src/api/stable/Booking";
import Select from "../Form/FormSelect";
import { TextOptionProps } from "../Form/FormOptions";
import _, { Dictionary } from "lodash";
import FormInput, { Feedback } from "../Form/FormInput";
import FormDatePicker from "../Form/FormDatePicker";
import { useTranslation } from "react-i18next";

interface PeriodicBookingFormProps {
  value?: PeriodicBooking;
  onChange: (value?: PeriodicBooking) => void;
  validation?: Dictionary<string[]>;
}

const PeriodicBookingForm = (props: PeriodicBookingFormProps) => {
  const { value, onChange, validation } = props;
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setValue = (name: string, v: any) => {
    onChange(_.set(value || { periodic: {}, repeating: {} }, name, v) as PeriodicBooking);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSet = (name: string) => (v: any) => setValue(name, v);

  const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let v: any = event.target.value;
    if (event.target.type === 'number' || event.target.name === 'repeating.type') {
      v = Number(v);
    } else if (event.target.name === 'repeating.dayOfWeek') {
      v = v.split(',').map(Number);
    }
    setValue(event.target.name, v);
  }

  const onChangeRepeatTypeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === 'none') {
      onChange(undefined);
    } else {
      onChangeEvent(event);
    }
  }

  const repeatTypes: TextOptionProps[] = [
    { id: 'none', value: 'none', label: t('common.bool.no') },
    { id: '0', value: '0', label: t('booking.repeating.type.0') },
    { id: '1', value: '1', label: t('booking.repeating.type.1') },
    { id: '2', value: '2', label: t('booking.repeating.type.2') },
    { id: '3', value: '3', label: t('booking.repeating.type.3') },
  ]
  const weekDayOptions: TextOptionProps[] = [
    { id: '1', value: '1', label: t('common.days.monday') },
    { id: '2', value: '2', label: t('common.days.tuesday') },
    { id: '3', value: '3', label: t('common.days.wednesday') },
    { id: '4', value: '4', label: t('common.days.thursday') },
    { id: '5', value: '5', label: t('common.days.friday') },
    { id: '6', value: '6', label: t('common.days.saturday') },
    { id: '0', value: '0', label: t('common.days.sunday') },
  ]

  return (
    <div>
      <div className="flex">
        <div className="flex-grow">
          <Select
            id="repeating.type"
            value={String(value?.repeating?.type)}
            onChange={onChangeRepeatTypeEvent}
            options={repeatTypes}
            placeholder={t('booking.repeat.repeatActivity')}
          />
        </div>
      </div>
      {value?.repeating?.type !== undefined && (
        <>
          {(value.repeating.type === PeriodicBookingRepeatingType._0 || value.repeating.type === PeriodicBookingRepeatingType._1) && <>
            <div className="flex">
              <div className="pt-3 text-nowrap">
                {t('booking.repeat.repeatEvery')}
              </div>
              <div className="px-5">
                <FormInput.WithoutLabel
                  type="number"
                  name="repeating.every"
                  value={value?.repeating?.every}
                  onChange={onChangeEvent}
                  min={1}
                  step={1}
                  className="w-16"
                  placeholder="1"
                />
                <Feedback isInvalid={_.has(validation, 'periodic.repeating.every')} validationFeedback={_.get(validation, 'periodic.repeating.every') as string | undefined} name="periodic.repeating.every" />
              </div>
              <div className="pt-3">
                {value.repeating.type === PeriodicBookingRepeatingType._0 && t('booking.repeat.days')}
                {value.repeating.type === PeriodicBookingRepeatingType._1 && t('booking.repeat.weeks')}
              </div>
            </div>
          </>}
          {value.repeating.type === PeriodicBookingRepeatingType._1 && <>
            <div className="flex">
              <div className="pt-3">{t('booking.repeat.atDaysOfWeek')}:</div>
              <div className="px-5 flex flex-col">
                <Select
                  id="repeating.dayOfWeek"
                  value={value.repeating.dayOfWeek?.map(d => String(d))}
                  onChange={onChangeEvent}
                  options={weekDayOptions}
                  multiple
                />
                <Feedback isInvalid={_.has(validation, 'periodic.repating.dayOfWeek')} validationFeedback={_.get(validation, 'periodic.repating.dayOfWeek') as string | undefined} name="periodic.repating.dayOfWeek" />
              </div>
            </div>
          </>}
          <div className="flex">
            <div className="pt-3 text-nowrap">
              {t('booking.repeat.repeatFrom')}
            </div>
            <div className="px-5 flex flex-col">
              <FormDatePicker value={value.period?.start} onChange={onSet('period.start')} />
              <Feedback isInvalid={_.has(validation, 'periodic.period.start')} validationFeedback={_.get(validation, 'periodic.period.start') as string | undefined} name="periodic.period.start" />
            </div>
            <div className="pt-3">
              {t('booking.repeat.until')}
            </div>
            <div>
              <div className="px-5 py-2 flex flex-col">
                <div className="flex flex-row-reverse">
                  <FormDatePicker value={value.period?.end} onChange={onSet('period.end')} required={value !== undefined} />
                </div>
                <Feedback isInvalid={_.has(validation, 'periodic.period.end')} validationFeedback={_.get(validation, 'periodic.period.end') as string | undefined} name="periodic.period.end" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default PeriodicBookingForm;