import _, { NumericDictionary } from "lodash";

export const mapIsoWeekdays = (callback: (day: number) => void) => {
  return [1, 2, 3, 4, 5, 6, 0].map(callback);
}

export function mapIsoWeekdaysDictionary<TType, TReturn>(values: NumericDictionary<TType>, callback: (day: number, value: TType) => TReturn | undefined) {
  return mapIsoWeekdays(day => {
    if (_.has(values, day)) {
      return callback(day, values[day]);
    } else {
      return undefined;
    }
  });
}