import { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrainingType } from 'src/api/stable/Stable';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import { FormImageComponentRef, FormImageUploader } from 'src/components/Form/FormImage';
import FormInput from 'src/components/Form/FormInput'
import FormSection from 'src/components/Layout/Panel/FormSection';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';
import { Uploader } from 'src/hooks/useUploader';

export interface GeneralFormComponentProps {
  form: IForm<TrainingType>;
  uploader: Uploader;
  uploaderRef: RefObject<FormImageComponentRef>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form, uploader, uploaderRef } = props;
  const { t } = useTranslation();
  const [isGroupTraining, setIsGroupTraining] = useState(form.data.maximalRiders !== undefined && form.data.maximalRiders > 1);
  const isDeveloper = useClaim('Developer');

  useEffect(() => {
    if (form.data.maximalRiders === undefined || form.data.maximalRiders <= 1) return;
    setIsGroupTraining(true);
  }, [form.data.maximalRiders]);
  useEffect(() => {
    if (isGroupTraining) return;
    form.set('maximalRiders', undefined);
  }, [isGroupTraining])

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      <div className="mb-10">
        <span>{t("common.fields.photo")}</span>
        <FormImageUploader ref={uploaderRef} uploader={uploader} noUploadButton={form.data.id === undefined} />
        <span className="text-xs text-gray-500">{t('ui.uploader.squarePhotos')}</span>
      </div>
      <div className="mb-10">
        <FormInput.Overlapping suffix="minutes" {...form.input('duration', 'number', { required: true, min: 15, max: 1000, placeholder: t('stable.trainingTypes.fields.duration'), "aria-description": t('stable.trainingTypes.descriptions.duration') })} />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping suffix="PLN" {...form.input('price', 'number', { required: true, min: 1, max: 10000000, placeholder: t('stable.trainingTypes.fields.price'), "aria-description": t('stable.trainingTypes.descriptions.price') })} />
      </div>
      <div className="mb-10">
        <FormCheckbox.Input checked={isGroupTraining} onChange={(e) => setIsGroupTraining(e.target.checked)} placeholder={t('stable.trainings.fields.groupTraining')} aria-description={t('stable.trainings.descriptions.groupTraining')} />
      </div>
      {isGroupTraining && (
        <>
          <div className="mb-10">
            <FormInput.Overlapping {...form.input('maximalRiders', 'number', { min: 1, max: 100, placeholder: t('stable.trainingTypes.fields.maximalRiders'), "aria-description": t('stable.trainingTypes.descriptions.maximalRiders') })} />
          </div>
        </>
      )}
      <div className="mb-10">
        <FormCheckbox.Input {...form.input('isLimitedToDefinedTerms', 'checkbox', { value: "true" })} placeholder={t('stable.trainings.fields.isLimitedToDefinedTerms')} aria-description={t('stable.trainings.descriptions.isLimitedToDefinedTerms')} />
      </div>
    </FormSection>
  )
}