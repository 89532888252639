import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { DetailedList, DetailedListItem } from 'src/components/Data/DetailedList';
import { HttpQueryOrder, QueryOrderDirection, TrainingType, TrainingTypeTranslation, TrainingTypesClient } from 'src/api/stable/Stable';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { useEffect, useState } from 'react';
import { TrainingTypeImage } from 'src/components/Stable/TrainingTypeImage';
import ContentParse from 'src/components/Cms/ContentParse';
import Spinner from 'src/components/Feedback/Spinner';
import SectionHeader from 'src/components/Typography/SectionHeader';
import SectionDescription from 'src/components/Typography/SectionDescription';
import Page from 'src/components/Cms/Page';
import { useTranslation } from 'react-i18next';
import { MetaPageHead } from 'src/components/Meta/MetaHead';

export const TrainingTypesView = () => {
  return (
    <>
      <MetaPageHead id="218bfcad-3b63-4b9a-ad0f-7b9ee9ce4610" />
      <TrainingTypes />
    </>
  )
}

const TrainingTypes = () => {
  const { i18n } = useTranslation();
  const enityTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const apiConfiguration = useApiConfiguration();
  const trainingTypesClient = new TrainingTypesClient(apiConfiguration);
  const [trainingTypes, setTrainingTypes] = useState<TrainingType[] | undefined>(undefined);

  useEffect(() => {
    trainingTypesClient
      .get(
        undefined,
        [{ property: 'created', direction: QueryOrderDirection.ASC }] as HttpQueryOrder[],
        48,
        undefined,
        undefined,
        undefined
      )
      .then(response => setTrainingTypes(response.items ?? []));
  }, []);

  const currencyFormat = (value: number) => value.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' });
  const durationFormat = (value: number) => Math.ceil(value).toLocaleString(i18n.resolvedLanguage, { style: 'unit', unit: 'minute' });

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="md:flex">
          <div className="w-full lg:w-1/3 mr-8 sm:text-left">
            <SectionHeader><Page id="218bfcad-3b63-4b9a-ad0f-7b9ee9ce4610" title raw /></SectionHeader>
            <SectionDescription color><Page id="218bfcad-3b63-4b9a-ad0f-7b9ee9ce4610" content raw /></SectionDescription>
          </div>
          {typeof trainingTypes === 'undefined' && <Spinner className="mx-auto h-8" />}
          <DetailedList>
            {trainingTypes?.map(type => <DetailedListItem key={type.id || ""} id={type.id || ""} image={<TrainingTypeImage id={type.id!} className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" />} label={enityTranslation.getCurrentTranslation(type)?.name ?? ""} className="text-gray-700"><ContentParse>{`${enityTranslation.getCurrentTranslation(type)?.description ?? ""}<br/>${currencyFormat(type.price)} &bull; ${durationFormat(type.duration)}`}</ContentParse></DetailedListItem>)}
          </DetailedList>
        </div>
      </div>
    </div >
  )
}

export default TrainingTypes;