import parse from 'html-react-parser';

import './ContentParse.css';

interface ContentParseComponentProps {
  children?: string;
}

const ContentParse = (props: ContentParseComponentProps) => {
  const { children } = props;

  if (!children) return <></>;

  return (
    <>
      <div className="content-parse">
        {parse(children ?? "")}
      </div>
    </>
  );
}

export default ContentParse;