import useForm, { IForm } from "src/hooks/useForm";
import { WizardStepProps } from "./Index";
import { Dictionary } from "lodash";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight, faCirclePlus, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import FormInput from "src/components/Form/FormInput";
import FormCheckbox from "src/components/Form/FormCheckbox";
import { Horse, HorseInstructor, Instructor, InstructorsClient, InstructorTranslation } from "src/api/stable/Stable";
import { ChangeEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import Alert from "src/components/Feedback/Alert";
import WizardPill from "./WizardPill";
import Editor from "src/components/Form/FormEditor";

export interface WizardStepInstructorsForm {
  rows: Instructor[];
}

export interface WizardStepInstructorsProps extends WizardStepProps {
  data: Instructor[];
  setData: (data: Instructor[]) => void;
  horses: Horse[];
}

interface InstructorFormProps {
  form: IForm<WizardStepInstructorsForm>;
  instructor: Instructor;
  index: number;
  onClickDelete: () => void;
  horses?: Horse[];
}

enum InstructorValidation {
  GivenName,
  Surname,
  Horses
}

const useValidation = (instructor: Instructor) => {
  return useMemo(() => validateEntity(instructor), [
    instructor,
    instructor.user?.givenName, instructor.user?.surname,
    instructor.horseInstructors, instructor.isAvailableForAnyHorse
  ]);
}

const validateEntity = (row: Instructor): InstructorValidation[] => {
  const result = [] as InstructorValidation[];
  if (!row.user?.givenName) result.push(InstructorValidation.GivenName);
  if (!row.user?.surname) result.push(InstructorValidation.Surname);
  if (!row.isAvailableForAnyHorse && (row.horseInstructors ?? []).length === 0) result.push(InstructorValidation.Horses);
  return result;
}

const InstructorForm = (props: InstructorFormProps) => {
  const { form, instructor, index, horses, onClickDelete } = props;
  const { t } = useTranslation();
  const validation = useValidation(instructor);

  const onChangeHorse: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, checked } = e.target;
    const stringValue = String(value);
    let relations = [...instructor.horseInstructors || []] as HorseInstructor[];
    if (checked && !relations.some(hi => hi.horseId === value)) {
      relations = [...relations, { horseId: stringValue }] as HorseInstructor[];
    }
    else if (!checked && relations.some(hi => hi.horseId === stringValue)) {
      relations = [...relations.filter(hi => hi.horseId !== stringValue)] as HorseInstructor[];
    }
    form.set(`rows.${index}.horseInstructors`, relations);
  }

  return (
    <div className="border-t border-gray-100 pt-4 mt-4">
      {instructor.id && (
        <Alert.Warning title="Usuwanie istniejących danych" noClose>
          Usunięcie tego insruktora może spowodować usunięcie danych z bazy danych, w tym przypisanych do niego treningów.
        </Alert.Warning>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
        <div>
          <FormInput.Overlapping
            {...form.input(
              `rows.${index}.user.givenName`,
              'text',
              {
                placeholder: t('users.fields.givenName'),
                required: true
              })
            }
          />
        </div>
        <div>
          <FormInput.Overlapping
            {...form.input(
              `rows.${index}.user.surname`,
              'text',
              {
                placeholder: t('users.fields.surname'),
                required: true
              })
            }
          />
        </div>
        <div>
          <FormInput.Overlapping
            {...form.input(
              `rows.${index}.user.email`,
              'email',
              {
                placeholder: t('users.fields.email'),
                required: true
              })
            }
          />
        </div>
        <div>
        </div>
        <div>
          <p className="font-medium text-sm color-gray-600 mb-2">Konie, na których może prowadzić treningi:</p>
          <div className="text-center">
            <FormCheckbox.Input {...form.input(`rows.${index}.isAvailableForAnyHorse`, 'checkbox', { value: 'true' })} aria-description={t('stable.instructors.fields.isAvailableForAnyHorse')} />
          </div>
          <hr className="my-4" />
          {!instructor.isAvailableForAnyHorse &&
            <FormCheckbox.Set title="Horses">
              <div className="grid grid-cols-1 lg:grid-cols-2">
                {horses?.map((horse, horseId) => <FormCheckbox.Input
                  value={horseId}
                  checked={instructor.horseInstructors?.some(hi => hi.horseId === String(horseId))}
                  aria-description={horse.translations?.find(_t => true)?.name} onChange={onChangeHorse}
                />)}
              </div>
            </FormCheckbox.Set>
          }
          {validation.includes(InstructorValidation.Horses) && <span className="text-xs text-rose-700">{t('validation.AtLeastOneItem')}</span>}
        </div>
        <div>
          <Editor
            {...form.input(`rows.${index}.translations.0.description`, "text", { placeholder: t('common.fields.description') })}
            key={`rows.${index}.translations.0.description`}
            style={{ height: '50vh' }}
            minimal
          />
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <Button type="button" colorName="rose" className="text-sm px-5 font-medium" onClick={onClickDelete}>
          <FontAwesomeIcon icon={faTimesCircle} className="mr-3" />
          Usuń
        </Button>
      </div>
    </div>
  )
}

export default (props: WizardStepInstructorsProps) => {
  const { nextStep, prevStep, data, setData, horses } = props;
  const form = useForm({ rows: [] } as WizardStepInstructorsForm);
  const [currentInstructor, setCurrentInstructor] = useState<number | undefined>(undefined);

  const apiConfiguration = useApiConfiguration();
  const apiClient = new InstructorsClient(apiConfiguration);

  useEffect(() => {
    if (data.length > 0) {
      form.setData({ rows: data.map(normalizeEntity) });
      return;
    }
    fetch();
  }, []);

  const normalizeEntity = (entity: Instructor) => {
    entity.horseInstructors = entity?.horseInstructors?.filter(hi => hi.horseId !== undefined && horses[Number(hi.horseId)] !== undefined);
    return entity;
  }

  const mapEntity = (entity: Instructor) => {
    entity.horseInstructors = entity?.horseInstructors?.map(hi => {
      const horseIndex = horses.findIndex(h => h.id === hi.horseId);
      hi.horseId = horseIndex !== -1 ? String(horseIndex) : undefined;
      return hi;
    });
    return entity;
  }

  const fetch = () => {
    form.setPending(true);
    apiClient
      .get(undefined, undefined, 1000, 0, undefined, undefined)
      .then(response => {
        form.setData({ rows: response.items?.map(mapEntity).map(normalizeEntity) ?? [] });
        return response;
      })
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  const onSubmit = () => {
    setData([...form.data.rows] as Instructor[]);
    nextStep();
  }
  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    return result;
  }

  const onClickDelete = (index: number) => {
    if (form.data.rows[index].id) {
      if (confirm('Czy na pewno chcesz usunąć tego instruktora?')) {
        deleteRow(index);
      }
    }
    else {
      deleteRow(index);
    }
  }

  const deleteRow = (index: number) => {
    form.setData({
      ...form.data,
      rows: form.data.rows.filter((_, i) => i !== index)
    } as WizardStepInstructorsForm);
  }

  const addRow = () => {
    form.setData({
      ...form.data,
      rows:
        [
          ...form.data.rows,
          {
            user: {
              givenName: '',
              surname: '',
              email: ''
            },
            translations: [{
              culture: 'pl',
              name: '',
              description: ''
            } as InstructorTranslation],
            isAvailableForAnyRider: true,
            isAvailableForGuest: true,
            isAvailableForAnyHorse: false,
            horseInstructors: [] as HorseInstructor[],
          } as Instructor
        ]
    } as WizardStepInstructorsForm);
    setCurrentInstructor(form.data.rows.length);
  }

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
      <p className="text-sm text-gray-600">
        Teraz dodajmy instruktorów. Możesz dokonać wyboru z jakimi końmi mogą prowadzić treningi.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
        {form.data.rows?.map((instructor, index) => <WizardPill
          label={(instructor) => `${instructor.user?.givenName} ${instructor.user?.surname}`}
          key={index}
          row={instructor}
          onClick={() => setCurrentInstructor(index)}
          active={index === currentInstructor}
          validation={useValidation}
        />)}
        <div className="bg-emerald-100 rounded-md text-xs px-5 py-3 text-emerald-700 hover:bg-emerald-200 active:bg-emerald-400 cursor-pointer" onClick={addRow}>
          <FontAwesomeIcon icon={faCirclePlus} className="mr-1" /> Dodaj kolejnego instruktora
        </div>
      </div>
      {currentInstructor !== undefined && form.data.rows.some((_instructor, index) => index === currentInstructor) !== false && <InstructorForm instructor={form.data.rows[currentInstructor]} index={currentInstructor} onClickDelete={() => onClickDelete(currentInstructor!)} horses={horses} form={form} />}
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <Button type="button" colorName="gray" className="px-5 py-3" onClick={prevStep}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
          Powrót
        </Button>
        <Button type="submit" colorName="primary" className="px-5 py-3" disabled={form.data.rows.length === 0 || form.data.rows.some(i => !i.user?.givenName || !i.user?.surname || !i.user.email || (!i.isAvailableForAnyHorse && (i.horseInstructors ?? []).length === 0))}>
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          Przejdź dalej
        </Button>
      </div>
    </form>
  );
};
