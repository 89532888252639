import { useEffect, useRef } from 'react';
import { isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from 'react-i18next';
import QRCode from "react-qr-code";
import Alert from 'src/components/Feedback/Alert';
import { MetaHead } from 'src/components/Meta/MetaHead';
import config from 'src/config/config';
import useTenant from 'src/hooks/useTenant';
import useToken from 'src/hooks/useToken';
import useUser from 'src/hooks/useUser';

const Download = () => {
  const appleApp = 'redini-aplikacja-je%C5%BAdziecka/id6737122460';
  const androidApp = 'com.h0pe.redini';
  const domain = window.location.origin;
  const timer = useRef<NodeJS.Timeout | undefined>(undefined);
  const { t, i18n } = useTranslation();
  const tenant = useTenant();
  const token = useToken();
  const user = useUser();
  const deepLink = user ? `redini://${tenant}/auth/${token}` : `redini://${tenant}`;

  const redirect = () => {
    if (config.mobileApp) {
      if (isAndroid) {
        window.location.replace(deepLink);
        setTimeout(function () {
          window.location.replace(`https://play.google.com/store/apps/details?id=${androidApp}`);
        }, 2000);
      } else if (isIOS) {
        window.location.replace(deepLink);
        setTimeout(function () {
          window.location.replace(`https://apps.apple.com/app/${appleApp}`);
        }, 2000);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (token && !user) return;
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(redirect, 500);
  }, [user, token]);

  if (!config.mobileApp) {
    return (
      <div className="max-w-7xl mx-auto my-8">
        <Alert.Error title="App not supported" noClose />
      </div>
    )
  }

  return (
    <>
      <MetaHead title={t('ui.download.header')} />
      <div className="max-w-7xl mx-auto my-8">
        <div className="md:flex gap-8">
          <QRCode value={`${domain}/${i18n.resolvedLanguage}/download`} className="min-w-96 w-1/2 mx-auto my-auto md:mx-0" bgColor="transparent" fgColor="black" />
          <div className="px-8 lg:px-0">
            <h1 className="text-3xl text-gray-400 py-5 border-b border-gray-200">{t('ui.download.header')}</h1>
            <h2 className="text-5xl text-[--color-primary-700] pt-5">{t('ui.download.slogan')}</h2>
            <div className="text-lg text-gray-500 py-5 mb-5 border-b border-gray-200">{t('ui.download.description')}</div>
            <div className="flex gap-5">
              <a href="https://redini.app/app-store" target="_blank" rel="noreferrer">
                <img className="h-12" src="/img/mobile/app-store-pl.webp" alt="Download on the App Store" title="Download on the App Store" />
              </a>
              <a href="https://redini.app/google-play" target="_blank" rel="noreferrer">
                <img className="h-12" src="/img/mobile/google-play-pl.webp" alt="Get it on Google Play" title="Get it on Google Play" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Download;