import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs, { Tab } from "src/components/Tabs/Pills";
import GeneralPersonalizationSection from "./PersonalizationSections/General";
import LandingPersonalizationSection from "./PersonalizationSections/Landing";
import HeadersPersonalizationSection from "./PersonalizationSections/Headers";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { setBreadcrumbs } from "src/store/application/actions";

const PersonalizationView = () => {
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();
  const tabs: Tab[] = [
    { id: 'general', name: 'panel.settings.general.header' },
    { id: 'landing', name: 'panel.settings.layout.landing.header' },
    { id: 'headers', name: 'panel.settings.layout.headers' },
  ]
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "panel.settings.layout.header", href: "/panel/personalization" }
      ])
    );
  }, []);

  return (
    <>
      <div className="mb-5">
        <h1 className="leading-1 text-2xl mb-8">{t('panel.settings.layout.header')}</h1>
        <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        {currentTab === 'general' && <GeneralPersonalizationSection />}
        {currentTab === 'landing' && <LandingPersonalizationSection />}
        {currentTab === 'headers' && <HeadersPersonalizationSection />}
      </div>
    </>
  )
}

export default PersonalizationView;