
import { useEffect, useState } from 'react';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import GridSelect, { GridSelectOption } from 'src/components/Form/GridSelect';
import { useTranslation } from 'react-i18next';
import useUser from 'src/hooks/useUser';
import { Instructor, InstructorTranslation, TrainingType, Training } from 'src/api/stable/Booking';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import ContentParse from 'src/components/Cms/ContentParse';
import Spinner from 'src/components/Feedback/Spinner';
import Avatar from 'src/components/User/Avatar';
import { RiderTrainingsClient } from 'src/api/stable/Booking';
import LoadingOverlay from 'src/components/Feedback/LoadingOverlay';
import BookTrainingHeader from './BookTrainingHeader';

export interface InstructorsComponentProps {
  type: TrainingType;
  instructor?: Instructor;
  onSelect: (value?: Instructor) => void;
  onGoBack: () => void;
}

const Instructors = (props: InstructorsComponentProps) => {
  const { type, onSelect, onGoBack } = props;
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const trainingsClient = new RiderTrainingsClient(apiConfiguration);
  const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [loading, setLoading] = useState(false);

  const user = useUser();
  const [instructor, setInstructor] = useState<string | undefined>(props.instructor?.id);
  const entityTranslation = useEntityTranslation<Instructor, InstructorTranslation>();

  useEffect(() => {
    setLoading(true);
    trainingsClient
      .instructors({ typeId: type?.id } as Training)
      .then(response => setInstructors(response.items ?? []))
      .finally(() => setLoading(false));
  }, []);

  const options = [...[{
    id: 'any',
    value: undefined,
    image: <Avatar userId={"any"} className="max-w-100 max-h-100 mx-auto rounded-md" />,
    label: t('stable.instructors.any.label'),
    description: <ContentParse>{t('stable.instructors.any.description')}</ContentParse>,
  }
  ], ...instructors.map((instructor) => ({
    id: instructor.id,
    value: instructor.id,
    image: <Avatar userId={instructor.userId!} className="max-w-100 max-h-100 mx-auto rounded-md" />,
    label: entityTranslation.getCurrentTranslation(instructor)?.name,
    description: <ContentParse>{entityTranslation.getCurrentTranslation(instructor)?.description ?? ""}</ContentParse>,
    disabled: !instructor.isAvailableForGuest && user === undefined
  }) as GridSelectOption)];

  const onSubmit = () => {
    onSelect(instructors.find(t => t.id == instructor));
  }

  return (
    <div className="relative max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
      {loading && <LoadingOverlay />}
      <BookTrainingHeader onClickBack={onGoBack} disabledBack={loading} onClickNext={onSubmit} disabledNext={loading} >
        {t('stable.trainings.booking.selectInstructor')}
      </BookTrainingHeader>
      <div className="border-y border-y-gray-200 py-5 my-5">
        {typeof instructors === 'undefined' && <Spinner className="mx-auto h-8" />}
        <GridSelect options={options} value={instructor} onChange={(e) => setInstructor(e.target.value)} full />
      </div>
      <BookTrainingHeader onClickBack={onGoBack} disabledBack={loading} onClickNext={onSubmit} disabledNext={loading} />
    </div>
  )
}

export default Instructors;