import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle, faSave } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'src/components/Feedback/Toast';
import { GroupTraining, GroupTrainingParticipant, PeriodicBookingDeleteRequest, PeriodicUpdateDecision, Training, TrainingsClient } from 'src/api/stable/Booking';
import PaymentMethodSelect from 'src/components/Accountancy/PaymentMethodSelect';
import Button from 'src/components/Actions/Button';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormInput from 'src/components/Form/FormInput'
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import HorseSelect from 'src/components/Stable/HorseSelect';
import RiderSelect from 'src/components/Stable/RiderSelect';
import useFunctionality from 'src/hooks/stores/useFunctionality';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useClaim from 'src/hooks/useClaim';
import useForm, { IForm } from 'src/hooks/useForm';

export interface ParticipantsFormComponentProps {
  form: IForm<GroupTraining>;
}

export interface GroupTrainingParticipantRowProps {
  groupTraining: GroupTraining;
  participant?: GroupTrainingParticipant;
  onDelete?: () => void;
  onCreate?: (response: Training) => void;
  onUpdate?: (response: Training) => void;
}

export function ParticipantRow(props: GroupTrainingParticipantRowProps) {
  const { groupTraining, participant, onDelete, onCreate } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');
  const paymentsFunctionality = useFunctionality('module-payments');
  const form = useForm(participant ?? {} as GroupTrainingParticipant);

  const apiConfiguration = useApiConfiguration();
  const apiClient = new TrainingsClient(apiConfiguration);

  const onSubmit = () => {
    if (form.pending) return;
    if (form.data.bookingId) {
      form.setPending(true);
      apiClient
        .find(form.data.bookingId)
        .then(
          (response) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { horse, type, rider, instructor, place, ...dto } = response;
            dto.riderId = form.data.riderId;
            dto.horseId = form.data.horseId;
            dto.isConfirmed = form.data.isConfirmed;
            dto.isPaid = form.data.isPaid;
            dto.paymentMethodId = form.data.paymentMethodId;
            dto.periodicUpdateDate = groupTraining.start;
            dto.periodicUpdateDecision = PeriodicUpdateDecision._0;
            if (dto.paymentMethodId === "undefined" || dto.paymentMethodId === "null") {
              delete dto.paymentMethodId;
            }
            return apiClient
              .update(response.id!, dto as Training)
              .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
              .catch(e => form.catchAnyException(e, true));
          }
        )
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      form.setPending(true);
      apiClient
        .create({
          instructorId: groupTraining.instructorId,
          typeId: groupTraining.typeId,
          placeId: groupTraining.placeId,
          start: groupTraining.start,
          end: groupTraining.end,
          riderId: form.data.riderId,
          horseId: form.data.horseId,
          isConfirmed: form.data.isConfirmed,
          isPaid: form.data.isPaid,
          paymentMethodId: form.data.paymentMethodId
        } as Training)
        .then((response) => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          form.setData({} as GroupTrainingParticipant);
          if (onCreate) {
            onCreate(response);
          }
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const onClickDelete = () => {
    if (!form.data.bookingId) return;
    if (form.pending) return;
    form.setPending(true);
    apiClient
      .delete(form.data.bookingId, {
        periodicUpdateDate: groupTraining.start,
        periodicUpdateDecision: PeriodicUpdateDecision._0
      } as PeriodicBookingDeleteRequest)
      .then(() => {
        if (onDelete) {
          onDelete();
        }
      })
      .catch(e => form.catchAnyException(e, true))
      .finally(() => form.setPending(false));
  }

  return (
    <tr>
      {isDeveloper && <td>
        <FormInput.WithoutLabel name="id" placeholder="Id" value={form.data.bookingId} readOnly />
      </td>}
      <td>
        <Select
          id="isConfirmed"
          value={form.data.isConfirmed !== null ? (form.data.isConfirmed ? "true" : "false") : "null"}
          onChange={(e) => form.setData({ ...form.data, isConfirmed: e.target.value === "true" ? true : e.target.value === "false" ? false : null } as Training)}
          options={[
            { id: "true", value: "true", label: t('common.bool.yes') },
            { id: "false", value: "false", label: t('common.bool.no') },
            { id: "null", value: "null", label: t('ui.choose') }
          ] as TextOptionProps[]}
        />
      </td>
      <td>
        <FormCheckbox.Input {...form.input('isPaid', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
      </td>
      <td>
        <RiderSelect
          {...form.input('riderId', 'select', {})}
          onChange={(riderId, rider) => form.setData({ ...form.data, riderId: riderId as string, rider: rider } as Training)}
          value={form.data.riderId} />
      </td>
      <td>
        <HorseSelect
          {...form.input('horseId', 'select', {})}
          onChange={(horseId, horse) => form.setData({ ...form.data, horseId: horseId as string, horse: horse } as Training)}
          value={form.data.horseId} />
      </td>
      {paymentsFunctionality && (
        <td>
          <PaymentMethodSelect
            {...form.input('paymentMethodId', 'select', {})}
            onChange={(methodId) => form.setData({ ...form.data, paymentMethodId: methodId as string } as Training)}
            value={form.data.paymentMethodId} />
        </td>
      )}
      <td>
        <div className="flex flex-row gap-x-5">
          {form.data.bookingId ? (
            <>
              <Button type="button" colorName="sky" className="px-5 py-3" onClick={onSubmit}>
                <FontAwesomeIcon icon={faSave} />
              </Button>
              <Button type="button" colorName="red" className="px-5 py-3" onClick={onClickDelete}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </Button>
            </>
          ) : (
            <>
              <Button type="button" colorName="sky" className="px-5 py-3" onClick={onSubmit}>
                <FontAwesomeIcon icon={faPlusCircle} />
              </Button>
            </>
          )}
        </div>
      </td>
    </tr>
  )
}

export default function ParticipantsForm(props: ParticipantsFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const paymentsFunctionality = useFunctionality('module-payments');

  useEffect(() => {
    if (!form.data.start) return;
    const endDate = new Date(form.data.start.getTime());
    if (form.data.end) {
      endDate.setHours(form.data.end.getHours());
      endDate.setMinutes(form.data.end.getMinutes());
      endDate.setSeconds(form.data.end.getSeconds());
    }
    form.setData({ ...form.data, end: endDate } as Training)
  }, [form.data.start]);

  useEffect(() => {
    if (!form.data.periodic) return;
    if (!form.data.start) return;
    if (form.data.periodic.period?.start) return;
    form.set('periodic.period.start', moment(form.data.start).add(1, 'day').startOf('day').toDate());
  }, [form.data.periodic])

  const onDeleteParticipant = (id: string) => {
    form.setData({
      ...form.data, participants: _.filter(form.data.participants, (p) => p.bookingId !== id)
    } as GroupTraining);
  };

  const onCreateParticipant = (training: Training) => {
    const map = {
      bookingId: training.id,
      riderId: training.riderId,
      horseId: training.horseId,
      isConfirmed: training.isConfirmed,
      isPaid: training.isPaid,
      paymentMethodId: training.paymentMethodId
    } as GroupTrainingParticipant;
    form.setData({
      ...form.data,
      participants: [...form.data.participants ?? [], map]
    } as GroupTraining);
  };

  return (
    <>
      <FormSection
        title={t('stable.trainings.form.participants.header')}
        description={t('stable.trainings.form.participants.subheader')}
        full
      >
        <table className="w-full">
          <thead>
            <tr>
              <th className="font-medium uppercase text-sm text-gray-500">Id</th>
              <th className="font-medium uppercase text-sm text-gray-500">{t('stable.trainings.fields.isConfirmed')}</th>
              <th className="font-medium uppercase text-sm text-gray-500">{t('stable.trainings.fields.isPaid')}</th>
              <th className="font-medium uppercase text-sm text-gray-500">{t('stable.riders.item')}</th>
              <th className="font-medium uppercase text-sm text-gray-500">{t('stable.horses.item')}</th>
              {paymentsFunctionality && <th className="font-medium uppercase text-sm text-gray-500">{t('accountancy.paymentMethods.item')}</th>}
            </tr>
          </thead>
          <tbody>
            {form.data.participants?.map(
              (participant, index) => <ParticipantRow
                key={index}
                participant={participant}
                groupTraining={form.data}
                onDelete={() => onDeleteParticipant(participant.bookingId!)}
              />)}
          </tbody>
          <tfoot>
            <ParticipantRow
              groupTraining={form.data}
              onCreate={onCreateParticipant}
            />
          </tfoot>
        </table>
      </FormSection>
    </>
  )
}
