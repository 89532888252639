import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Training } from 'src/api/stable/Booking';
import PaymentMethodSelect from 'src/components/Accountancy/PaymentMethodSelect';
import PeriodicBookingForm from 'src/components/Booking/PeriodicBookingForm';
import Alert from 'src/components/Feedback/Alert';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormDatePicker from 'src/components/Form/FormDatePicker';
import FormInput from 'src/components/Form/FormInput'
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import HorseSelect from 'src/components/Stable/HorseSelect';
import InstructorSelect from 'src/components/Stable/InstructorSelect';
import PlaceSelect from 'src/components/Stable/PlaceSelect';
import RiderSelect from 'src/components/Stable/RiderSelect';
import TrainingTypeSelect from 'src/components/Stable/TrainingTypeSelect';
import useFunctionality from 'src/hooks/stores/useFunctionality';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<Training>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');
  const paymentsFunctionality = useFunctionality('module-payments');
  const [isDefinedTerm, setDefinedTerm] = useState(false);

  useEffect(() => {
    if (!form.data.start) return;
    const endDate = new Date(form.data.start.getTime());
    if (form.data.end) {
      endDate.setHours(form.data.end.getHours());
      endDate.setMinutes(form.data.end.getMinutes());
      endDate.setSeconds(form.data.end.getSeconds());
    }
    form.setData({ ...form.data, end: endDate } as Training)
  }, [form.data.start]);

  useEffect(() => {
    if (!form.data.periodic) return;
    if (!form.data.start) return;
    if (form.data.periodic.period?.start) return;
    form.set('periodic.period.start', moment(form.data.start).add(1, 'day').startOf('day').toDate());
  }, [form.data.periodic])

  return (
    <>
      <FormSection
        title={t('common.form.general.header')}
        description={t('common.form.general.subheader')}
      >
        {isDeveloper && <div className="mb-10">
          <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
        </div>}
        <div className="mb-10">
          <FormInput.Clean name="number" placeholder={t('common.fields.number')} value={form.data.number} readOnly />
        </div>
        {!form.data.id && (
          <div className="mb-10">
            <FormCheckbox.Input checked={isDefinedTerm} onChange={(e) => setDefinedTerm(e.target.checked)} placeholder={t('stable.trainings.fields.groupTrainingSession')} aria-description={t('stable.trainings.descriptions.groupTrainingSession')} />
          </div>
        )}
        <div className="mb-10">
          <div className="flex justify-between">
            <div>
              <FormDatePicker
                {...form.input('start', 'date', { placeholder: t('stable.trainings.fields.start') })}
                value={form.data.start}
                onChange={(date) => form.setData({ ...form.data, start: date } as Training)}
                time={15}
              />
            </div>
            <div>
              <FormDatePicker
                {...form.input('end', 'date', { placeholder: t('stable.trainings.fields.end') })}
                value={form.data.end}
                onChange={(date) => form.setData({ ...form.data, end: date } as Training)}
                time={15}
                timeOnly={true}
              />
            </div>
          </div>
        </div>
        {!isDefinedTerm && (
          <>
            <div className="mb-10">
              <Select
                id="isConfirmed"
                placeholder={t('stable.trainings.fields.isConfirmed')}
                value={form.data.isConfirmed !== null ? (form.data.isConfirmed ? "true" : "false") : "null"}
                onChange={(e) => form.setData({ ...form.data, isConfirmed: e.target.value === "true" ? true : e.target.value === "false" ? false : null } as Training)}
                options={[
                  { id: "true", value: "true", label: t('common.bool.yes') },
                  { id: "false", value: "false", label: t('common.bool.no') },
                  { id: "null", value: "null", label: t('ui.choose') }
                ] as TextOptionProps[]}
              />
            </div>
            <div className="mb-10">
              <span>{t("stable.trainings.fields.isPaid")}</span>
              <FormCheckbox.Set title={t("stable.trainings.fields.isPaid")}>
                <FormCheckbox.Input {...form.input('isPaid', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
              </FormCheckbox.Set>
            </div>
          </>
        )}
        <div className="mb-10">
          <TrainingTypeSelect
            placeholder={t('stable.trainingTypes.item')}
            {...form.input('typeId', 'select', {})}
            value={form.data.typeId}
            onChange={(typeId, type) => form.setData({ ...form.data, typeId: typeId as string, type: type } as Training)}
            onlyLimitedToDefinedTerms={isDefinedTerm}
          />
        </div>
        {!isDefinedTerm && (
          <div className="mb-10">
            <RiderSelect
              {...form.input('riderId', 'select', {})}
              onChange={(riderId, rider) => form.setData({ ...form.data, riderId: riderId as string, rider: rider } as Training)}
              value={form.data.riderId}
              placeholder={t('stable.riders.item')} />
          </div>
        )}
        <div className="mb-10">
          <InstructorSelect
            {...form.input('instructorId', 'select', {})}
            onChange={(instructorId, instructor) => form.setData({ ...form.data, instructorId: instructorId as string, instructor: instructor } as Training)}
            value={form.data.instructorId}
            placeholder={t('stable.instructors.item')} />
        </div>
        {!isDefinedTerm && (
          <div className="mb-10">
            <HorseSelect
              {...form.input('horseId', 'select', {})}
              onChange={(horseId, horse) => form.setData({ ...form.data, horseId: horseId as string, horse: horse } as Training)}
              value={form.data.horseId}
              placeholder={t('stable.horses.item')} />
          </div>
        )}
        <div className="mb-10">
          <PlaceSelect
            {...form.input('placeId', 'select', {})}
            onChange={(placeId, place) => form.setData({ ...form.data, placeId: placeId as string, place: place } as Training)}
            value={form.data.placeId}
            placeholder={t('stable.places.item')} />
        </div>
        {!isDefinedTerm && paymentsFunctionality &&
          <div className="mb-10">
            <PaymentMethodSelect
              {...form.input('paymentMethodId', 'select', {})}
              onChange={(methodId) => form.setData({ ...form.data, paymentMethodId: methodId as string } as Training)}
              value={form.data.paymentMethodId}
              placeholder={t('accountancy.paymentMethods.item')} />
          </div>}
        <PeriodicBookingForm validation={form.validationData} value={form.data.periodic} onChange={(periodic) => form.set('periodic', periodic)} />
      </FormSection>
      {form.data.periodic && <Alert.Information title={t('booking.repeat.training.header')} noClose>
        <p className="mb-3">{t('booking.repeat.training.about.content.0')}</p>
        <p className="mb-3">{t('booking.repeat.training.about.content.1')}</p>
        <p className="mb-3">{t('booking.repeat.training.about.content.2')}</p>
        <div className="pl-3">
          <ul className="list-disc list-inside">
            <li>{t('booking.repeat.training.about.items.0')}</li>
            <li>{t('booking.repeat.training.about.items.1')}</li>
            <li>{t('booking.repeat.training.about.items.2')}</li>
          </ul>
        </div>
      </Alert.Information>}
    </>
  )
}
