import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientGalleryCategoriesClient, Content, ContentTranslation } from 'src/api/cms/Cms';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormInput from 'src/components/Form/FormInput'
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useClaim from 'src/hooks/useClaim';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<Content>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const isDeveloper = useClaim('Developer');

  const apiConfiguration = useApiConfiguration();
  const categoryClient = new ClientGalleryCategoriesClient(apiConfiguration);
  const [categories, setCategories] = useState<Content[] | undefined>(undefined);
  useEffect(() => {
    if (form.data?.parentId) {
      categoryClient.get(undefined, undefined, undefined, undefined, undefined, undefined)
        .then(e => setCategories(e.items))
        .catch(() => alert('Can`t load parent'));
    }

  }, [form.data?.parentId])

  const categoryOptions = categories
    ?.map(c => ({
      id: c.id,
      value: c.id,
      label: entityTranslation.getCurrentTranslation(c)?.title
    } as TextOptionProps)) ??
    [] as TextOptionProps[];

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      {false && <div className="mb-10">
        <Select {...form.input('parentId', 'select', { placeholder: t('common.fields.category') })} options={categoryOptions} />
      </div>}
      <div className="mb-10">
        <span>{t("common.fields.visible")}</span>
        <FormCheckbox.Set title={t("common.fields.visible")}>
          <FormCheckbox.Input {...form.input('isVisible', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>
    </FormSection>
  )
}