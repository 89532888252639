import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientNewsfeedPostsClient, HttpQueryOrder, QueryOrderDirection } from "src/api/cms/Cms";
import { TrainingsClient, TrainingType } from "src/api/stable/Booking";
import { HorsesClient, InstructorsClient, PlacesClient, TrainingTypesClient, TrainingTypeTranslation } from "src/api/stable/Stable";
import Button from "src/components/Actions/Button";
import Alert from "src/components/Feedback/Alert";
import Spinner from "src/components/Feedback/Spinner";
import LocalizedLink from "src/components/Router/LocalizedLink";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import WizardModal from "./WizardModal";

interface UnavailableTrainingType {
  type: TrainingType;
  reason: string;
}

const Insights = () => {
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const [wizardModal, setWizardModal] = useState(false);

  const [horses, setHorses] = useState<boolean>();
  const [instructors, setInstructors] = useState<boolean>();
  const [places, setPlaces] = useState<boolean>();
  const [trainingTypes, setTrainingTypes] = useState<boolean>();
  const [isActive, setActive] = useState<boolean>();
  const [trainingTypesToCheck, setTrainingTypesToCheck] = useState<TrainingType[]>([]);
  const [unavailableTrainingTypes, setUnavailableTrainingTypes] = useState<UnavailableTrainingType[]>([]);

  const trainingTypeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();

  const horsesApiClient = new HorsesClient(apiConfiguration);
  const instructorsApiClient = new InstructorsClient(apiConfiguration);
  const placesApiClient = new PlacesClient(apiConfiguration);
  const trainingTypesApiClient = new TrainingTypesClient(apiConfiguration);
  const trainingsClient = new TrainingsClient(apiConfiguration);
  const postsClient = new ClientNewsfeedPostsClient(apiConfiguration);

  useEffect(() => {
    const lastMonth = moment(new Date()).subtract(1, 'month').toDate();
    horsesApiClient.get([], [], 1, 0, undefined, undefined)
      .then(response => setHorses(response.totalCount !== 0))
      .catch(console.error);
    instructorsApiClient.get([], [], 1, 0, undefined, undefined)
      .then(response => setInstructors(response.totalCount !== 0))
      .catch(console.error);
    placesApiClient.get([], [], 1, 0, undefined, undefined)
      .then(response => setPlaces(response.totalCount !== 0))
      .catch(console.error);
    trainingTypesApiClient.get([], [], 1000, 0, undefined, undefined)
      .then(response => {
        setTrainingTypes(response.totalCount !== 0);
        setTrainingTypesToCheck(response.items ?? []);
      })
      .catch(console.error);
    postsClient.get([], [{ property: 'created', direction: QueryOrderDirection.DESC }] as HttpQueryOrder[], 1, 0, undefined, undefined)
      .then(response => setActive(response?.items?.filter(p => p.created && p.created > lastMonth).length !== 0))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (horses === false && instructors === false && places === false && trainingTypes === false) {
      setWizardModal(true);
    }
  }, [horses, instructors, places, trainingTypes]);

  useEffect(() => {
    const trainingTypeToCheck = trainingTypesToCheck.find(_t => true);
    if (!trainingTypeToCheck) return;
    trainingsClient.checkConsistency(trainingTypeToCheck.id)
      .then(response => {
        if (response !== 'OK') {
          setUnavailableTrainingTypes([...unavailableTrainingTypes ?? [], { type: trainingTypeToCheck, reason: response }]);
        }
      })
      .catch(() => setUnavailableTrainingTypes([...unavailableTrainingTypes ?? [], { type: trainingTypeToCheck, reason: 'UnexceptedInconsistency' }]))
      .finally(() => setTrainingTypesToCheck([...trainingTypesToCheck].filter(t => t.id !== trainingTypeToCheck.id)))
  }, [trainingTypesToCheck]);

  const isBookingConsistent = unavailableTrainingTypes.length === 0;

  const insights = {
    horses,
    instructors,
    places,
    trainingTypes,
    isBookingConsistent,
    isActive
  };

  const isLoading = useMemo(() => _.values(insights).some(i => i === undefined) || trainingTypesToCheck?.length > 0, [insights, trainingTypesToCheck]);

  return (
    <div className="w-full py-3 flex-grow">
      <h2 className="leading-2 text-xl mt-3">{t('panel.insights.header')}</h2>
      <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
        <dl className="mx-auto gap-y-5">
          {_.values(insights).every(i => i === true) && <Alert.Success title={t('panel.insights.allGood')} noClose>{t('panel.insights.thereAreNoInsights')}</Alert.Success>}
          {(horses === false || instructors === false || places === false || trainingTypes === false) && <Alert.Error title={t('panel.insights.bookingLacksOfConfiguration')} noClose>
            <div className="flex justify-between">
              <div>
                <p className="mb-4">
                  {t('panel.insights.configurationNotEnoughToEnableBookingService')}<br />
                  {t('panel.insights.fulfillMissingBookingConfiguration')}
                </p>
                <p className="mb-4 font-medium">{t('panel.insights.missingItems')}:</p>
                <ul className="list-disc pl-8">
                  {horses === false && <li>{t('panel.insights.noHorses')},</li>}
                  {instructors === false && <li>{t('panel.insights.noInstructors')},</li>}
                  {places === false && <li>{t('panel.insights.noPlaces')},</li>}
                  {trainingTypes === false && <li>{t('panel.insights.noTrainingTypes')}</li>}
                </ul>
              </div>
              <div className="flex flex-col gap-y-1 text-end">
                {horses === false && <div><LocalizedLink to="/panel/stable/horses/create"><Button colorName="rose" className="w-full">{t('stable.horses.actions.create')}</Button></LocalizedLink></div>}
                {instructors === false && <div><LocalizedLink to="/panel/stable/instructors/create"><Button colorName="rose" className="w-full">{t('stable.instructors.actions.create')}</Button></LocalizedLink></div>}
                {places === false && <div><LocalizedLink to="/panel/stable/places/create"><Button colorName="rose" className="w-full">{t('stable.places.actions.create')}</Button></LocalizedLink></div>}
                {trainingTypes === false && <div><LocalizedLink to="/panel/stable/trainig-types/create"><Button colorName="rose" className="w-full">{t('stable.trainingTypes.actions.create')}</Button></LocalizedLink></div>}
              </div>
            </div>
          </Alert.Error>}
          {isBookingConsistent === false && <Alert.Error title={t('panel.insights.bookingInconsistent')} noClose>
            <div className="flex justify-between">
              <div>
                <p className="mb-4">
                  {t('panel.insights.configurationNotEnoughToEnableBookingService')}<br />
                  {t('panel.insights.noBookingPosibilites')}<br />
                  {t('panel.insights.importantToDefineRelations')}
                </p>
                <p className="mb-4 font-medium">{t('panel.insights.unavailableTrainingTypes')}:</p>
                <ul className="list-disc pl-8">
                  {unavailableTrainingTypes?.map(type => <li>{trainingTypeTranslation.getCurrentTranslation(type.type)?.name}: {t(`panel.insights.inconsistencies.${type.reason}`)}</li>)}
                </ul>
              </div>
            </div>
          </Alert.Error>}
          {isActive === false && <Alert.Warning title={t('panel.insights.poorActivity')} noClose>
            <div className="flex justify-between">
              <div>
                <p>
                  {t('panel.insights.noUpdatesDuringLastMonth')}<br />
                  {t('panel.insights.weRecommendToStayConnected')}<br />
                  {t('panel.insights.freshActivitiesAttractMoreVisitors')}
                </p>
              </div>
              <div>
                <LocalizedLink to="/panel/content/newsfeed/posts/create"><Button colorName="amber">{t('cms.newsfeed.posts.actions.create')}</Button></LocalizedLink>
              </div>
            </div>
          </Alert.Warning>}
          {false && <Alert.Information title={t('panel.insights.absences')} noClose>
            <div className="flex flex-col gap gap-y-4">
              <div>
                <p className="mb-4 font-medium">{t('panel.insights.instructorAbsences')}:</p>
                <ul className="list-disc pl-8">
                  <li>Jan Kowalski</li>
                </ul>
              </div>
              <div>
                <p className="mb-4 font-medium">{t('panel.insights.horseAbsences')}:</p>
                <ul className="list-disc pl-8">
                  <li>Błyskawica</li>
                </ul>
              </div>
            </div>
          </Alert.Information>}
        </dl>
        {isLoading && <div className="py-6"><Spinner className="mx-auto h-8" /></div>}
      </div>
      <WizardModal setVisible={setWizardModal} visible={wizardModal} />
    </div >
  );
}
export default Insights;