
import { useEffect, useState } from 'react';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import GridSelect, { GridSelectOption } from 'src/components/Form/GridSelect';
import { useTranslation } from 'react-i18next';
import useUser from 'src/hooks/useUser';
import { Horse, HorseTranslation, Instructor, TrainingType, RiderTrainingsClient, Training } from 'src/api/stable/Booking';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import ContentParse from 'src/components/Cms/ContentParse';
import Spinner from 'src/components/Feedback/Spinner';
import { HorseImage } from 'src/components/Stable/HorseImage';
import LoadingOverlay from 'src/components/Feedback/LoadingOverlay';
import BookTrainingHeader from './BookTrainingHeader';

export interface HorsesComponentProps {
  type: TrainingType;
  instructor?: Instructor;
  horse?: Horse;
  onSelect: (value?: Horse) => void;
  onGoBack: () => void;
}

const Horses = (props: HorsesComponentProps) => {
  const { type, instructor, onSelect, onGoBack } = props;
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const trainingsClient = new RiderTrainingsClient(apiConfiguration);
  const [horses, setHorses] = useState<Horse[]>([]);

  const user = useUser();
  const [horse, setHorse] = useState<string | undefined>(props.horse?.id);
  const [loading, setLoading] = useState(false);
  const entityTranslation = useEntityTranslation<Horse, HorseTranslation>();

  useEffect(() => {
    setLoading(true);
    trainingsClient
      .horses({ typeId: type!.id, instructorId: instructor?.id } as Training)
      .then(response => setHorses(response.items ?? []))
      .finally(() => setLoading(false));
  }, []);

  const options = [...[{
    id: 'any',
    value: undefined,
    image: <HorseImage id={"any"} className="max-w-100 max-h-100 mx-auto rounded-md" />,
    label: t('stable.horses.any.label'),
    description: <ContentParse>{t('stable.horses.any.description')}</ContentParse>,
  }
  ], ...horses.map((horse) => ({
    id: horse.id,
    value: horse.id,
    image: <HorseImage id={horse.id!} className="max-w-100 max-h-100 mx-auto rounded-md" />,
    label: entityTranslation.getCurrentTranslation(horse)?.name,
    description: <ContentParse>{entityTranslation.getCurrentTranslation(horse)?.description ?? ""}</ContentParse>,
    disabled: !horse.isAvailableForGuest && user === undefined
  }) as GridSelectOption)];

  const onSubmit = () => {
    onSelect(horses.find(t => t.id == horse));
  }

  return (
    <div className="relative max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
      {loading && <LoadingOverlay />}
      <BookTrainingHeader onClickBack={onGoBack} disabledBack={loading} onClickNext={onSubmit} disabledNext={loading} >
        {t('stable.trainings.booking.selectHorse')}
      </BookTrainingHeader>
      <div className="border-y border-y-gray-200 py-5 my-5">
        {typeof horses === 'undefined' && <Spinner className="mx-auto h-8" />}
        <GridSelect options={options} value={horse} onChange={(e) => setHorse(e.target.value)} full />
      </div>
      <BookTrainingHeader onClickBack={onGoBack} disabledBack={loading} onClickNext={onSubmit} disabledNext={loading} />
    </div >
  )
}

export default Horses;