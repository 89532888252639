import Spinner from "src/components/Feedback/Spinner";
import { WizardStepProps } from "./Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/pro-duotone-svg-icons";
import { useEffect, useState } from "react";
import { Horse, HorseInstructor, HorsesClient, HorseTrainingType, HorseTranslation, HttpQueryFilter, Instructor, InstructorsClient, InstructorTrainingType, InstructorTranslation, Place, PlacesClient, PlaceTranslation, TrainingType, TrainingTypePlace, TrainingTypesClient, TrainingTypeTranslation } from "src/api/stable/Stable";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { User, UsersClient } from "src/api/access/Authority";
import Alert from "src/components/Feedback/Alert";
import Button from "src/components/Actions/Button";
import LocalizedLink from "src/components/Router/LocalizedLink";
import { OptionalEntity } from "src/api/Interfaces";
import { IEntity } from "src/components/Table/Table";
import { PeriodicBookingDeleteRequest, PeriodicUpdateDecision, TrainingsClient } from "src/api/stable/Booking";

interface WizardStepInstallationProps extends WizardStepProps {
  horses?: Horse[];
  instructors?: Instructor[];
  places?: Place[];
  trainingTypes: TrainingType[];
}

interface EntitiesResult<TEntity extends OptionalEntity> {
  insert: TEntity[];
  update: TEntity[];
  delete: TEntity[];
}

async function retryIfError<T>(operation: () => Promise<T>, retries: number = 3): Promise<T> {
  let error = undefined;
  for (let i = 0; i < retries; i++) {
    try {
      return await operation();
    } catch (ex) {
      error = ex;
    }
  }
  throw error;
}

const entities = <TEntity extends OptionalEntity>(
  localEntities: TEntity[],
  remoteEntities: TEntity[]
): EntitiesResult<TEntity> => {
  if (!remoteEntities || remoteEntities.length === 0)
    return { insert: localEntities, update: [], delete: [] };

  if (remoteEntities.some(e => !e.id))
    throw new Error('Remote entities must have id');

  return {
    insert: localEntities.filter(e => !e.id),
    update: localEntities.filter(e => e.id && remoteEntities.some(re => re.id === e.id)),
    delete: remoteEntities.filter(e => !localEntities.some(le => le.id === e.id))
  }
}

const commitEntities = async <T extends IEntity>(
  localEntities: T[],
  remoteEntities: T[],
  createOperation: (entity: T) => Promise<T>,
  updateOperation: (id: string, entity: T) => Promise<T>,
  deleteOperation: (id: string) => Promise<void>
): Promise<T[]> => {
  const {
    insert: toInsert,
    update: toUpdate,
    delete: toDelete
  } = entities(localEntities, remoteEntities);

  const result = [];

  try {
    for (const entity of toInsert) {
      console.log('inserting entity', entity);
      const response = await retryIfError(() => createOperation({ ...entity } as T), 3);
      result.push(response);
    }

    for (const entity of toUpdate) {
      console.log('updating entity', entity.id, entity);
      const response = await retryIfError(() => updateOperation(entity.id!, { ...entity } as T), 3);
      result.push(response);
    }

    for (const entity of toDelete) {
      console.log('deleting entity', entity.id);
      await retryIfError(() => deleteOperation(entity.id!), 3);
    }
  }
  catch (error) {
    console.error(error);
    throw error
  }

  return result;
}

enum InstallationStep {
  Fetch,
  Horses,
  Instructors,
  Places,
  TrainingTypes,
  Users,
  Done
}

export default (props: WizardStepInstallationProps) => {
  const [step, setStep] = useState<InstallationStep>(InstallationStep.Fetch);

  const [remoteHorses, setRemoteHorses] = useState<Horse[] | undefined>();
  const [remoteInstructors, setRemoteInstructors] = useState<Instructor[] | undefined>();
  const [remotePlaces, setRemotePlaces] = useState<Place[] | undefined>();
  const [remoteTrainingTypes, setRemoteTrainingTypes] = useState<TrainingType[] | undefined>();

  const [horses, setHorses] = useState([...props.horses || []] as Horse[]);
  const [instructors, setInstructors] = useState([...props.instructors || []] as Instructor[]);
  const [places, setPlaces] = useState([...props.places || []] as Place[]);
  const [trainingTypes, setTrainingTypes] = useState([...props.trainingTypes || []] as TrainingType[]);

  const [error, setError] = useState<string | undefined>();

  const apiConfiguration = useApiConfiguration();

  const trainingsApiClient = new TrainingsClient(apiConfiguration);
  const horsesApiClient = new HorsesClient(apiConfiguration);
  const instructorsApiClient = new InstructorsClient(apiConfiguration);
  const placesApiClient = new PlacesClient(apiConfiguration);
  const trainingTypesApiClient = new TrainingTypesClient(apiConfiguration);
  const usersClient = new UsersClient(apiConfiguration);

  useEffect(() => {
    console.log(`current step: ${step}`);
    if (step === InstallationStep.Fetch) {
      fetchEntities()
        .then(() => setStep(InstallationStep.Horses))
        .catch(error => setError(error.status));
    } else if (step === InstallationStep.Horses) {
      updateHorses()
        .then(() => setStep(InstallationStep.Instructors))
        .catch(error => setError(error.status));
    } else if (step === InstallationStep.Instructors) {
      updateInstructors()
        .then(() => setStep(InstallationStep.Places))
        .catch(error => setError(error.status));
    } else if (step === InstallationStep.Places) {
      updatePlaces()
        .then(() => setStep(InstallationStep.TrainingTypes))
        .catch(error => setError(error.status));
    } else if (step === InstallationStep.TrainingTypes) {
      updateTrainingTypes()
        .then(() => setStep(InstallationStep.Users))
        .catch(error => setError(error.status));
    } else if (step === InstallationStep.Users) {
      setStep(InstallationStep.Done);
    }
  }, [step]);

  const fetchEntities = () =>
    Promise.all([
      fetchHorses(),
      fetchInstructors(),
      fetchPlaces(),
      fetchTrainingTypes()
    ])

  const fetchHorses = () =>
    horsesApiClient
      .get(undefined, undefined, 1000, 0, undefined, undefined)
      .then(response => {
        setRemoteHorses(response.items ?? []);
      })
      .catch(error => setError(error.status));

  const fetchInstructors = () =>
    instructorsApiClient
      .get(undefined, undefined, 1000, 0, undefined, undefined)
      .then(response => {
        setRemoteInstructors(response.items ?? []);
      })
      .catch(error => setError(error.status));

  const fetchPlaces = () =>
    placesApiClient
      .get(undefined, undefined, 1000, 0, undefined, undefined)
      .then(response => {
        setRemotePlaces(response.items ?? []);
      })
      .catch(error => setError(error.status));

  const fetchTrainingTypes = () =>
    trainingTypesApiClient
      .get(undefined, undefined, 1000, 0, undefined, undefined)
      .then(response => {
        setRemoteTrainingTypes(response.items ?? []);
      })
      .catch(error => setError(error.status));

  const updateHorses = async () =>
    commitEntities<Horse>(
      horses.map(prepareHorse),
      remoteHorses ?? [],
      (horse) => horsesApiClient.create(horse),
      (id, horse) => horsesApiClient.update(id, horse),
      (id) => deleteHorse(id),
    )
      .then(response => {
        setHorses(response)
        console.log('setHorses');
        return response;
      });

  const updateInstructors = () =>
    commitEntities<Instructor>(
      instructors.map(prepareInstructor),
      remoteInstructors ?? [],
      (instructor) => createInstructor(instructor),
      (id, instructor) => instructorsApiClient.update(id, instructor),
      (id) => deleteInstructor(id),
    )
      .then(response => {
        setInstructors(response)
        return response;
      });

  const updatePlaces = () =>
    commitEntities<Place>(
      places.map(preparePlace),
      remotePlaces ?? [],
      (place) => placesApiClient.create(place),
      (id, place) => placesApiClient.update(id, place),
      (id) => deletePlace(id),
    )
      .then(response => {
        setPlaces(response)
        return response;
      });

  const updateTrainingTypes = () =>
    commitEntities<TrainingType>(
      trainingTypes.map(prepareTrainingType),
      remoteTrainingTypes ?? [],
      (trainingType) => trainingTypesApiClient.create(trainingType),
      (id, trainingType) => trainingTypesApiClient.update(id, trainingType),
      (id) => deleteTrainingType(id),
    )
      .then(response => {
        setTrainingTypes(response)
        return response;
      });


  const prepareHorse = (horse: Horse): Horse => {
    horse.translations = horse.translations?.map(t => ({ ...t, culture: 'pl' } as HorseTranslation)) || [];
    return horse;
  }

  const prepareInstructor = (instructor: Instructor): Instructor => {
    instructor.translations = instructor.translations?.map(t => ({ ...t, culture: 'pl', name: instructor.user?.givenName } as InstructorTranslation)) || [];
    instructor.horseInstructors = instructor.horseInstructors?.map(hi => ({ ...hi, horseId: horses[Number(hi.horseId)]?.id } as HorseInstructor)).filter(hi => hi.horseId) || [];
    return instructor;
  }

  const preparePlace = (place: Place): Place => {
    place.translations = place.translations?.map(t => ({ ...t, culture: 'pl' } as PlaceTranslation)) || [];
    return place;
  }

  const prepareTrainingType = (trainingType: TrainingType): TrainingType => {
    trainingType.translations = trainingType.translations?.map(t => ({ ...t, culture: 'pl' } as TrainingTypeTranslation)) || [];
    trainingType.horseTrainingTypes = trainingType.horseTrainingTypes?.map(r => ({ ...r, horseId: horses[Number(r.horseId)]?.id } as HorseTrainingType)).filter(htt => htt.horseId) || [];
    trainingType.instructorTrainingTypes = trainingType.instructorTrainingTypes?.map(r => ({ ...r, instructorId: instructors[Number(r.instructorId)]?.id } as InstructorTrainingType)).filter(itt => itt.instructorId) || [];
    trainingType.trainingTypePlaces = trainingType.trainingTypePlaces?.map(r => ({ ...r, placeId: places[Number(r.placeId)]?.id } as TrainingTypePlace)).filter(ttp => ttp.placeId) || [];
    trainingType.duration = Number(trainingType.duration);
    trainingType.price = Number(trainingType.price);
    return trainingType;
  }

  const deleteTrainings = async (filters: HttpQueryFilter[]): Promise<void> => {
    let trainings = undefined;
    do {
      console.log('fetching trainings');
      trainings = await trainingsApiClient.get(filters, undefined, 1000, 0, undefined, undefined);
      console.log('fetched trainings', trainings);
      for (const training of trainings.items ?? []) {
        console.log('deleting training', training.id);
        await deleteTraining(training.id!);
      }
    } while (trainings !== undefined && trainings.items && trainings.items.length > 0);
  }

  const deleteTraining = async (id: string): Promise<void> => {
    const training = await trainingsApiClient.find(id);
    if (training.periodic && training.periodic.id) {
      await trainingsApiClient.delete(id, {
        periodicUpdateDecision: PeriodicUpdateDecision._0,
        periodicUpdateDate: training.start
      } as PeriodicBookingDeleteRequest);
    } else {
      await trainingsApiClient.delete(id, {
        periodicUpdateDecision: PeriodicUpdateDecision._1,
        periodicUpdateDate: training.start
      } as PeriodicBookingDeleteRequest);
    }
  }

  const deleteHorse = async (id: string): Promise<void> => {
    deleteTrainings([{ property: 'horseId', value: id, type: '=' } as HttpQueryFilter]);
    console.log('deleting horse', id);
    await horsesApiClient.delete(id);
  }

  const deleteInstructor = async (id: string): Promise<void> => {
    deleteTrainings([{ property: 'instructorId', value: id, type: '=' } as HttpQueryFilter]);
    console.log('deleting instructor', id);
    await instructorsApiClient.delete(id);
  }

  const deletePlace = async (id: string): Promise<void> => {
    deleteTrainings([{ property: 'placeId', value: id, type: '=' } as HttpQueryFilter]);
    console.log('deleting place', id);
    await placesApiClient.delete(id);
  }

  const deleteTrainingType = async (id: string): Promise<void> => {
    deleteTrainings([{ property: 'trainingTypeId', value: id, type: '=' } as HttpQueryFilter]);
    console.log('deleting training type', id);
    await trainingTypesApiClient.delete(id);
  }

  const createInstructor = (instructor: Instructor) => new Promise<Instructor>((resolve, reject) => {
    usersClient.get([{ property: 'email', value: instructor.user?.email, type: '=' } as HttpQueryFilter], undefined, undefined, undefined, undefined, undefined)
      .then((response) => {
        if (response.items && response.items.length > 0) {
          const user = response.items.find(_u => true)!;
          usersClient.find(user.id!)
            .then(userResponse => {
              usersClient.update(user.id!, {
                ...user,
                culture: 'pl',
                roles: user.roles?.some(r => r === 'Instructor') ? [...userResponse.roles ?? []] : [...userResponse.roles ?? [], 'Instructor']
              } as User)
                .then((__userResponse) => { })
                .catch(error => reject(error));
              instructorsApiClient.create({ ...instructor, userId: user.id, user: undefined } as Instructor)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })

        } else {
          usersClient.create({ ...instructor.user, userName: instructor.user?.email, culture: 'pl', roles: ['Instructor'] } as User)
            .then((userResponse) => {
              instructorsApiClient.create({ ...instructor, userId: userResponse.id, user: undefined } as Instructor)
                .then((response) => resolve(response))
                .catch(error => reject(error))
            })
            .catch(error => reject(error));
        }
      });
  });

  return (
    <>
      <h3 className="text-lg">Teraz chwilkę nam to zajmie...</h3>
      {error && <Alert.Error title={error} />}
      <div className="text-center p-8">
        {step < InstallationStep.Done && <Spinner className="mx-auto h-16" />}
      </div>
      <div>
        <div>
          <Check title="Zmiana hasła" done />
        </div>
        <div>
          <Check title="Ustawianie wyglądu strony" done />
        </div>
        <div>
          <Check title="Ustawianie preferencji rezerwacji" done />
        </div>
        <div>
          <Check title="Weryfikacja konfiguracji" active={step == InstallationStep.Fetch} done={step >= InstallationStep.Fetch} />
        </div>
        <div>
          <Check title="Dodawanie koni" active={step == InstallationStep.Horses} done={step >= InstallationStep.Horses} />
        </div>
        <div>
          <Check title="Dodawanie instruktorów" active={step == InstallationStep.Instructors} done={step >= InstallationStep.Instructors} />
        </div>
        <div>
          <Check title="Dodawanie miejsc treningowych" active={step == InstallationStep.Places} done={step >= InstallationStep.Places} />
        </div>
        <div>
          <Check title="Dodawanie rodzajów treningu" active={step == InstallationStep.TrainingTypes} done={step >= InstallationStep.TrainingTypes} />
        </div>
        {step == InstallationStep.Done &&
          <div className="py-8 flex justify-between">
            <LocalizedLink to="/panel">
              <Button colorName="gray">
                Powrót do panelu
              </Button>
            </LocalizedLink>
            <LocalizedLink to="/">
              <Button colorName="primary">
                Przejdź do swojej wymarzonej wizytówki
              </Button>
            </LocalizedLink>
          </div>
        }
      </div>
    </>
  );
};

interface CheckProps {
  active?: boolean;
  done?: boolean;
  title: string;
}

const Check = (props: CheckProps) => {
  const { active, done, title } = props;
  if (done)
    return (<>
      <FontAwesomeIcon icon={faCheckCircle} className="text-emerald-700 opacity-50 mr-3" />
      <span className="text-gray-500">{title}</span>
    </>);
  if (active)
    return (<>
      <FontAwesomeIcon icon={faCircle} className="text-[--color-primary-700] mr-3" />
      <span className="text-gray-700">{title}</span>
    </>);
  return (<>
    <FontAwesomeIcon icon={faCircle} className="text-gray-200 mr-3" />
    <span className="text-gray-300">{title}</span>
  </>);
}