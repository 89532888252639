import { createRef, FC, useEffect, useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { TrainingType, TrainingTypesClient, TrainingTypeTranslation } from 'src/api/stable/Stable';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import TranslationForm from './Form/Translation';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import RidersForm from './Form/Riders';
import InstructorsForm from './Form/Instructors';
import HorsesForm from './Form/Horses';
import { FormImageComponentRef } from 'src/components/Form/FormImage';
import { getApiBaseUrl, ConfigurationApis } from 'src/config/config';
import useTenant from 'src/hooks/useTenant';
import { useUploader } from 'src/hooks/useUploader';
import PlacesForm from './Form/Places';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { TrainingsClient } from 'src/api/stable/Booking';

const TrainingTypesForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const [inconsistency, setInconsinstecy] = useState<string | undefined>();
  const apiClient = new TrainingTypesClient(apiConfiguration);
  const trainingsApiClient = new TrainingsClient(apiConfiguration);
  const form = useForm<TrainingType>({
    translations: [] as TrainingTypeTranslation[],
    isAvailableForAnyRider: true,
    isAvailableForGuest: true
  } as TrainingType);
  const authGuard = useAuthGuard('/panel/', ['TrainingTypesRead']);
  const TrainingTypesUpdateClaim = useClaim('TrainingTypesUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();
  const tenant = useTenant();

  const checkConsistency = () => {
    if (form.data.id === undefined) return;
    setInconsinstecy(undefined);
    trainingsApiClient
      .checkConsistency(form.data.id)
      .then(response => {
        setInconsinstecy(response);
        if (response !== 'OK') {
          Toast.error(t("panel.insights.unavailableTrainingType"), t(`panel.insights.inconsistencies.${response}`));
        }
      })
      .catch(() => setInconsinstecy('UnexceptedInconsistency'));
  };

  const photoUrl = `${getApiBaseUrl(ConfigurationApis.Stable)}/api/v1/training-types/${id}/photo?XTenantId=${tenant}&${(new Date()).getTime()}`;
  const uploadPhoto = (file: File) => new Promise<string>(
    (resolve, reject) => {
      const onAvatarUploadError = (e: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
        console.error(e);
        reject();
      }
      const onAvatarUploadSuccess = () => {
        const url = `${photoUrl}&${(new Date()).getTime()}`;
        resolve(url);
        if (id === 'create') {
          navigate(`/panel/stable/training-types/`);
        }
      }

      apiClient
        .uploadPhoto(form.data.id!, undefined, { fileName: file.name, data: file })
        .catch(onAvatarUploadError)
        .then(onAvatarUploadSuccess);
    }
  );
  const uploader = useUploader(uploadPhoto);
  const uploaderRef = createRef<FormImageComponentRef>();

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/stable/training-types/`)
      })
  }

  const onSubmit = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id !== 'create') {
      apiClient.update(id, form.data)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.form.saved"))
          checkConsistency();
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(form.data)
        .then((response) => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          form.setData({ ...response } as TrainingType);
          checkConsistency();
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const fetch = () => {
    if (!id) return;
    form.setPending(true);
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "stable.trainingTypes.group", href: "/panel/stable/training-types/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/stable/training-types/${id}` }
      ])
    );
    if (id !== 'create') uploader?.setPhoto(photoUrl);
  }, [id]);

  useEffect(() => {
    if (id === 'create' && form.data.id !== undefined) {
      if (uploaderRef.current?.file) {
        uploaderRef.current?.upload();
      } else {
        navigate(`/panel/stable/training-types/`);
      }
    }
  }, [form.data?.id])

  useEffect(() => {
    form.setReadOnly(!TrainingTypesUpdateClaim);
  }, [TrainingTypesUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <Header TrainingType={form.data} onDelete={onDelete} form={form} inconsistency={inconsistency} />
        <GeneralForm form={form} uploader={uploader} uploaderRef={uploaderRef} />
        <TranslationForm form={form} />
        <PlacesForm form={form} />
        <RidersForm form={form} />
        <InstructorsForm form={form} />
        <HorsesForm form={form} />
        <div className="mt-8 pt-8 flex flex-col-reverse justify-stretch items-center space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          {inconsistency !== undefined && inconsistency !== 'OK' && (
            <>
              <div className="text-sm text-rose-600">{t(`panel.insights.inconsistencies.${inconsistency}`)}</div>
              <FontAwesomeIcon icon={faTimesCircle} className="text-rose-600 size-8 m-2" title={t(`panel.insights.inconsistencies.${inconsistency}`)} />
            </>
          )}
          <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
            {t('common.actions.save')}
          </Button>
        </div>
      </form >
    </>
  )
}

export default TrainingTypesForm;