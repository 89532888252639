import { NumericDictionary } from "lodash";

export enum Layout {
    Vertical,
    Horizontal,
    PictureVertical,
    PictureHorizontal,
    MapVertical,
    MapHorizontal
}

export interface ConfigurationLayoutLanding {
    slider?: boolean;
    newsfeed?: boolean;
    aboutUs?: boolean;
    horses?: boolean;
    instructors?: boolean;
    trainingTypes?: boolean;
}

export interface ConfigurationLayout {
    landing: ConfigurationLayoutLanding;
    contactFormLayout: Layout;
    primaryColor: string;
    defaultFont: string;
}

export interface ConfigurationSocial {
    facebook?: string;
    instagram?: string;
    tiktok?: string;
    twitter?: string;
}

export interface WorkingHours {
    start: number[];
    end: number[];
}

export enum AuthorizationScope {
    Any,
    Users,
    Noone
}

export enum DayOfWeek {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday
}

export interface ConfigurationBooking {
    guests: boolean;
    requireEquestrianCentreAgreements: boolean;
    requirePhoneNumber: boolean;
    granularity: number;
    isHorseOccupiedBeforeEnabled?: boolean;
    automaticHorseAssignment?: boolean;
    selectTrainingType: AuthorizationScope;
    selectInstructor: AuthorizationScope;
    selectHorse: AuthorizationScope;
    hours?: NumericDictionary<WorkingHours>;
}

export interface ConfigurationState {
    layout: ConfigurationLayout;
    booking: ConfigurationBooking;
    social: ConfigurationSocial;
}

export const initialConfigurationState: ConfigurationState = {
    layout: {
        landing: {
            slider: true,
            horses: true,
            trainingTypes: true,
            instructors: true,
            aboutUs: true,
            newsfeed: true
        },
        contactFormLayout: Layout.Horizontal,
        primaryColor: 'gray',
        defaultFont: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
    },
    booking: {
        guests: true,
        requireEquestrianCentreAgreements: false,
        requirePhoneNumber: true,
        granularity: 30,
        selectTrainingType: AuthorizationScope.Any,
        selectInstructor: AuthorizationScope.Noone,
        selectHorse: AuthorizationScope.Noone,
        isHorseOccupiedBeforeEnabled: false,
        automaticHorseAssignment: true,
        hours: {
            1: { start: [0, 0], end: [0, 0] },
            2: { start: [0, 0], end: [0, 0] },
            3: { start: [0, 0], end: [0, 0] },
            4: { start: [0, 0], end: [0, 0] },
            5: { start: [0, 0], end: [0, 0] },
            6: { start: [0, 0], end: [0, 0] },
            0: { start: [0, 0], end: [0, 0] }
        }
    },
    social: {
    }
};