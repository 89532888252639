import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { HttpQueryOrder, QueryOrderDirection, TrainingType, TrainingTypeTranslation, TrainingTypesClient } from 'src/api/stable/Stable';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { useEffect, useState } from 'react';
import Spinner from 'src/components/Feedback/Spinner';
import SectionHeader from 'src/components/Typography/SectionHeader';
import SectionDescription from 'src/components/Typography/SectionDescription';
import Page from 'src/components/Cms/Page';
import { Coupon, CouponsClient, CouponTranslation } from 'src/api/stable/Booking';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/Actions/Button';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import useFunctionality from 'src/hooks/stores/useFunctionality';
import useUser from 'src/hooks/useUser';
import { MetaPageHead } from 'src/components/Meta/MetaHead';

export const PriceListView = () => {
  return (
    <>
      <MetaPageHead id="4fafb411-0491-4c02-b7f9-6de50a6aa17f" />
      <PriceList />
    </>
  )
}

const PriceList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useLocalizedNavigate();
  const user = useUser();
  const paymentsFunctionality = useFunctionality('module-payments');
  const trainingTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const couponTranslation = useEntityTranslation<Coupon, CouponTranslation>();
  const apiConfiguration = useApiConfiguration();
  const trainingTypesClient = new TrainingTypesClient(apiConfiguration);
  const couponsClient = new CouponsClient(apiConfiguration);
  const [trainingTypes, setTrainingTypes] = useState<TrainingType[] | undefined>(undefined);
  const [coupons, setCoupons] = useState<Coupon[] | undefined>(undefined);

  const onBuyCoupon = (coupon: Coupon) => {
    couponsClient.buy(coupon.id!)
      .then(response => navigate(`/stable/coupon-payment/${response.id}`))
      .catch(error => console.error(error));
  }

  useEffect(() => {
    trainingTypesClient
      .get(
        undefined,
        [{ property: 'created', direction: QueryOrderDirection.ASC }] as HttpQueryOrder[],
        48,
        undefined,
        undefined,
        undefined
      )
      .then(response => setTrainingTypes(response.items ?? []));
    couponsClient
      .get(
        undefined,
        [{ property: 'created', direction: QueryOrderDirection.ASC }] as HttpQueryOrder[],
        48,
        undefined,
        undefined,
        undefined
      )
      .then(response => setCoupons(response.items ?? []));
  }, []);

  const currencyFormat = (value: number) => value.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' });
  const durationFormat = (value: number) => Math.ceil(value).toLocaleString(i18n.resolvedLanguage, { style: 'unit', unit: 'minute' });

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="md:flex">
          <div className="w-full lg:w-1/3 mr-8 sm:text-left">
            <SectionHeader><Page id="4fafb411-0491-4c02-b7f9-6de50a6aa17f" title raw /></SectionHeader>
            <SectionDescription color><Page id="4fafb411-0491-4c02-b7f9-6de50a6aa17f" content raw /></SectionDescription>
          </div>
          <div className="sm:flex-grow">
            {typeof trainingTypes === 'undefined' && <Spinner className="mx-auto h-8" />}
            <h4 className="text-xl mb-4">{t('stable.trainingTypes.group')}</h4>
            <table className="w-full">
              <thead>
                <tr className="bg-[--color-primary-50]">
                  <th className="text-start font-medium p-3">{t('common.fields.name')}</th>
                  <th className="text-end font-medium p-3">{t('stable.trainingTypes.fields.duration')}</th>
                  <th className="text-end font-medium p-3">{t('stable.trainingTypes.fields.price')}</th>
                  <th className="text-end font-medium p-3"></th>
                </tr>
                {trainingTypes?.map(type => <tr key={type.id}>
                  <td className="p-3">{trainingTranslation.getCurrentTranslation(type)?.name}</td>
                  <td className="text-end p-3">{durationFormat(type.duration)}</td>
                  <td className="text-end p-3">{currencyFormat(type.price)}</td>
                  <td className="text-end p-3">
                    <LocalizedLink to={`/stable/book-training/${type.id}`}>
                      <Button colorName="primary">{t('stable.actions.bookRide')}</Button>
                    </LocalizedLink>
                  </td>
                </tr>)}
              </thead>
            </table>
            {paymentsFunctionality && <>
              <div className="mb-12"></div>
              {typeof coupons === 'undefined' && <Spinner className="mx-auto h-8" />}
              <h4 className="text-xl mb-4">{t('stable.coupons.group')}</h4>
              <table className="w-full">
                <thead>
                  <tr className="bg-[--color-primary-50]">
                    <th className="text-start font-medium p-3">{t('common.fields.name')}</th>
                    <th className="text-end font-medium p-3">{t('stable.trainings.group')}</th>
                    <th className="text-end font-medium p-3">{t('stable.coupons.validity.header')}</th>
                    <th className="text-end font-medium p-3">{t('stable.trainingTypes.fields.price')}</th>
                    <th className="text-end font-medium p-3"></th>
                  </tr>
                  {coupons?.map(coupon => <tr key={coupon.id}>
                    <td className="p-3">{couponTranslation.getCurrentTranslation(coupon)?.title}</td>
                    <td className="text-end p-3">{coupon.validity?.count}</td>
                    <td className="text-end p-3">{coupon.validity?.days?.toLocaleString(i18n.resolvedLanguage, { style: 'unit', unit: 'day' })}</td>
                    <td className="text-end p-3">{currencyFormat(coupon.price!)}</td>
                    <td className="text-end p-3">
                      {user && <Button colorName="primary" onClick={() => onBuyCoupon(coupon)}>{t('stable.actions.buyCoupon')}</Button>}
                    </td>
                  </tr>)}
                </thead>
              </table>
            </>}
          </div>
        </div>
      </div>
    </div >
  )
}

export default PriceList;