import { faCircleExclamation } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OptionalEntity } from "src/api/Interfaces";
import classNames from "src/components/Utilities/classNames";

export interface WizardPillProps<T extends OptionalEntity> {
  label: ((row: T) => string) | string;
  row: T;
  onClick: () => void;
  active: boolean;
  validation: (row: T) => unknown[];
}

export default <T extends OptionalEntity>(props: WizardPillProps<T>) => {
  const { label, row, onClick, active, validation } = props;
  const validationResult = validation(row);

  return (
    <div className={classNames([
      "rounded-md text-xs px-5 py-3 cursor-pointer",
      validationResult.length > 0 ? "bg-red-100 text-red-700" : (active ? "bg-[--color-primary-100] text-[--color-primary-700]" : "bg-gray-100 text-gray-700")
    ])} onClick={onClick}>
      {validationResult.length > 0 && <FontAwesomeIcon icon={faCircleExclamation} className="mr-1 text-red-700" />}
      {label instanceof Function ? label(row) : label}
    </div>
  );
}