import { useTranslation } from "react-i18next";
import classNames from "../Utilities/classNames";
import { createRef, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faRepeat, faTimesCircle, faUsers } from "@fortawesome/pro-duotone-svg-icons";
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

export interface CalendarEvent {
  id: string;
  label: string;
  description?: string;
  start: Date;
  end: Date;
  colorName: string;
  disabled?: boolean;
  active?: boolean;
  visible?: boolean;
  deletable?: boolean;
  isPeriodic?: boolean;
  isConfirmed?: boolean;
  isGroup?: boolean;
}

export interface AvailableHours {
  start: number[];
  end: number[];
}

export interface CalendarWeekViewComponentProps {
  startCalendar: Date;
  setStartCalendar?: (date: Date) => void;
  startHour: number;
  endHour: number;
  events?: CalendarEvent[];
  availableHours?: AvailableHours[];
  availableTerms?: AvailableTerm[];
  onClickEvent?: (event: CalendarEvent) => void;
  onDeleteEvent?: (event: CalendarEvent) => void;
  onClickTerm?: (start: Date, end: Date) => void;
  hideTerms?: boolean;
  className?: string;
  termInterval?: number;
  termGranulation?: number;
  isControllable?: boolean;
}

export interface AvailableTerm {
  start: Date;
  end: Date;
  slots?: number[];
  description?: string;
}

interface MobileDayHeaderComponentProps {
  onClick: () => void;
  isCurrent: boolean;
  date: Date;
}

interface MobileCalendarControllerProps {
  mobileDay: number;
  setMobileDay: (day: number) => void;
  startCalendar: Date;
  setStartCalendar: (date: Date) => void;
}

const MobileDayHeader = (props: MobileDayHeaderComponentProps) => {
  const { date, isCurrent, onClick } = props;
  const { i18n } = useTranslation();
  return (
    <button type="button" className="flex flex-col items-center pb-3 pt-2" onClick={onClick}>
      {date.toLocaleDateString(i18n.resolvedLanguage, { weekday: 'short' })}
      {!isCurrent && <span className="mt-1 flex h-8 w-8 items-center justify-center font-medium text-gray-900">
        {date.toLocaleDateString(i18n.resolvedLanguage, { day: '2-digit' })}
      </span>}
      {isCurrent && <span className="mt-1 flex h-8 w-8 items-center justify-center rounded-full bg-[--color-primary-600] font-medium text-white">
        {date.toLocaleDateString(i18n.resolvedLanguage, { day: '2-digit' })}
      </span>}
    </button>
  );
}

const MobileCalendarController = (props: MobileCalendarControllerProps) => {
  const { startCalendar, setStartCalendar, mobileDay, setMobileDay } = props;
  const { t } = useTranslation();

  const onClickPreviousWeek = () => {
    setStartCalendar(moment(startCalendar).subtract(7, 'days').startOf('isoWeek').toDate());
  }

  const onClickNextWeek = () => {
    setStartCalendar(moment(startCalendar).add(7, 'days').startOf('isoWeek').toDate());
  }

  const onClickToday = () => {
    const today = moment().startOf('isoWeek');
    setStartCalendar(today.toDate());
    const tmp = moment().get('weekday') - 1;
    setMobileDay(tmp === -1 ? 6 : tmp);
  }

  const onClickPreviousDay = () => {
    if (mobileDay === 0) {
      onClickPreviousWeek();
    }
    const tmp = (mobileDay - 1) % 7;
    setMobileDay(tmp === -1 ? 6 : tmp);
  }

  const onClickNextDay = () => {
    if (mobileDay === 6) {
      onClickNextWeek();
    }
    setMobileDay((mobileDay + 1) % 7);
  }

  const isPreviousWeekDisabled = moment(startCalendar).isSameOrBefore(moment().startOf('isoWeek'));
  const isPreviousDayDisabled = moment(startCalendar).add(mobileDay, 'days').startOf('day').isSameOrBefore(moment().startOf('day'));

  return (
    <div className="md:hidden w-full mb-8">
      <div className="relative flex items-center justify-center rounded-md bg-white shadow-sm md:items-stretch">
        <button
          type="button"
          className="flex flex-1 h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
          onClick={onClickPreviousWeek}
          disabled={isPreviousWeekDisabled}
        >
          <span className="sr-only">{t('ui.calendar.previousWeek')}</span>
          <FontAwesomeIcon icon={faAngleDoubleLeft} className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="flex flex-1 h-9 w-12 items-center justify-center  border-y  border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
          onClick={onClickPreviousDay}
          disabled={isPreviousDayDisabled}
        >
          <span className="sr-only">{t('ui.calendar.previousDay')}</span>
          <FontAwesomeIcon icon={faAngleLeft} className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="border flex-grow border-gray-300 px-3.5 py-2.5 text-xs font-medium text-gray-900 hover:bg-gray-50 focus:relative md:block"
          onClick={onClickToday}
        >
          {t('ui.calendar.today')}
        </button>
        <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
        <button
          type="button"
          className="flex flex-1 h-9 w-12 items-center justify-center border-y  border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
          onClick={onClickNextDay}
        >
          <span className="sr-only">{t('ui.calendar.nextDay')}</span>
          <FontAwesomeIcon icon={faAngleRight} className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="flex flex-1 h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
          onClick={onClickNextWeek}
        >
          <span className="sr-only">{t('ui.calendar.nextWeek')}</span>
          <FontAwesomeIcon icon={faAngleDoubleRight} className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}

const CalendarWeekView = (props: CalendarWeekViewComponentProps) => {
  const { hideTerms, startCalendar, setStartCalendar, startHour, endHour, events, onClickEvent, onDeleteEvent, onClickTerm, availableHours, availableTerms, className, termGranulation, termInterval, isControllable } = props;
  const container = createRef<HTMLDivElement>();
  const containerNav = createRef<HTMLDivElement>();
  const containerOffset = createRef<HTMLDivElement>();

  const days = _.range(0, 7).map((d) => {
    const date = new Date(startCalendar);
    date.setDate(startCalendar.getDate() + d);
    return date;
  });

  const [mobileDay, setMobileDay] = useState(days.findIndex(d => d.getDate() === (new Date()).getDate()) ?? days[0]);
  const isInDays = (date: Date) => days.some(d => date.getDate() === d.getDate() && date.getMonth() === d.getMonth());
  const isInMobileDay = (date: Date) => {
    if (days === undefined)
      return false;
    if (!_.has(days, mobileDay))
      return false;
    return days[mobileDay].getDate() === date.getDate() && days[mobileDay].getMonth() === date.getMonth();
  }

  const hours = _.range(startHour, endHour).map(h => new Date(days[0].getFullYear(), days[0].getMonth(), days[0].getDate(), h, 0, 0));

  const hoursRange = [startHour, endHour];

  const minDate = moment(new Date()).add(1, 'hour').toDate();
  const terms: AvailableTerm[] = !hideTerms ? (availableTerms || (availableHours ? days.map((day) => {
    const dayHours = availableHours[day.getDay()];
    if (!dayHours) return [];
    const dayStart = new Date(day);
    dayStart.setHours(dayHours.start[0], dayHours.start[1], 0, 0);
    const dayEnd = new Date(day);
    dayEnd.setHours(dayHours.end[0], dayHours.end[1], 0, 0);
    return _.range(dayHours.start[0], dayHours.end[1] != 0 ? dayHours.end[0] + 1 : dayHours.end[0])
      .filter(hour => new Date(day.getFullYear(), day.getMonth(), day.getDate(), hour, 59, 59) > minDate)
      .map((hour) => {
        return _.range(0, 60, termGranulation ?? 30).map((minute) => {
          const termStart = new Date(day.getFullYear(), day.getMonth(), day.getDate(), hour, minute, 0);
          const termEnd = moment(termStart).add(termInterval, 'm').toDate();
          return { start: termStart, end: termEnd };
        })
          .map(hour => ({ ...hour, intervalEnd: moment(hour.start).add(termInterval, 'm').toDate() }))
          .filter(term => term.start >= dayStart && term.intervalEnd <= dayEnd)
          .map(term => ({ start: term.start, end: term.end } as AvailableTerm));
      });
  }).flat().flat() : []))
    .filter(term => {
      if (!availableHours) return true;
      const start = moment(term.start);
      const end = moment(term.end);
      const dayHours = availableHours[start.get('weekday')];
      if (!dayHours) return false;
      const startOfDay = moment(new Date(start.get('year'), start.get('month'), start.get('date'), dayHours.start[0], dayHours.start[1], 0));
      const endOfDay = moment(new Date(start.get('year'), start.get('month'), start.get('date'), dayHours.end[0], dayHours.end[1], 0));
      if (start < startOfDay) return false;
      if (end > endOfDay) return false;
      return true;
    })
    .filter(term => !events?.some(e => moment(e.start).isBefore(term.end) && moment(e.end).isAfter(term.start))) : [];

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60
    if (!container.current) return;
    if (!containerNav.current) return;
    if (!containerOffset.current) return;
    container.current.scrollTop =
      ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) *
        currentMinute) /
      1440
  }, []);

  return (
    <>
      {isControllable && setStartCalendar && (
        <MobileCalendarController
          startCalendar={startCalendar}
          setStartCalendar={setStartCalendar}
          mobileDay={mobileDay}
          setMobileDay={setMobileDay}
        />
      )}
      <div ref={container} className={`isolate flex flex-auto flex-col overflow-auto bg-red-50 relative ${className}`}>
        <div style={{ width: '165%' }} className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
          <div
            ref={containerNav}
            className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
          >
            <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
              {days.map((day, i) => <MobileDayHeader key={i} date={day} onClick={() => setMobileDay(i)} isCurrent={mobileDay === i} />)}
            </div>

            <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
              <div className="col-end-1 w-14" />
              {days.map(d => <DayHeader key={d.getTime()} date={d} />)}
            </div>
          </div>
          <div className="md:hidden flex-auto">
            <div className="sticky top-0 backdrop-blur-sm left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 row-start-1 grid divide-y divide-gray-100"
                style={{ gridTemplateRows: `repeat(${hours.length}, minmax(5rem, 1fr))` }}
              >
                <div ref={containerOffset} className="row-end-1 h-10"></div>
                {hours.map((h, i) => <TimelineColumnRow key={i} time={h} />)}
              </div>

              {/* Available terms */}
              <ol
                className="col-start-1 row-start-1 grid grid-cols-1"
                style={{ gridTemplateRows: `2.5rem repeat(${12 * hours.length}, minmax(0, 1fr)) auto` }}
              >
                {terms.filter(term => isInMobileDay(term.start)).map((term, i) => <AvailableTermInput key={i} term={term} onClick={onClickTerm} duration={termInterval} hours={hoursRange} isMobile />)}
              </ol>

              {/* Events */}
              <ol
                className="col-start-1 row-start-1 grid grid-cols-1"
                style={{ gridTemplateRows: `2.5rem repeat(${12 * hours.length}, minmax(0, 1fr)) auto` }}
              >
                {events?.filter(event => event.visible !== false).filter(event => isInMobileDay(event.start)).map((event, i) => <Event key={i} event={event} onClick={onClickEvent} onDelete={onDeleteEvent} hours={hoursRange} isMobile />)}
              </ol>
            </div>
          </div>
          <div className="hidden md:flex flex-auto">
            <div className="sticky top-0 backdrop-blur-sm left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{ gridTemplateRows: `repeat(${hours.length}, minmax(5rem, 1fr))` }}
              >
                <div ref={containerOffset} className="row-end-1 h-10"></div>
                {hours.map((h, i) => <TimelineColumnRow key={i} time={h} />)}
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              {/* Available terms */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{ gridTemplateRows: `2.5rem repeat(${12 * hours.length}, minmax(0, 1fr)) auto` }}
              >
                {terms.map((term, i) => <AvailableTermInput key={i} term={term} onClick={onClickTerm} hours={hoursRange} duration={termInterval} />)}
              </ol>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{ gridTemplateRows: `2.5rem repeat(${12 * hours.length}, minmax(0, 1fr)) auto` }}
              >
                {events?.filter(event => event.visible !== false).filter(event => isInDays(event.start)).map((event, i) => <Event key={i} event={event} hours={hoursRange} onClick={onClickEvent} onDelete={onDeleteEvent} />)}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

interface AvailableTermInputComponentProps {
  term: AvailableTerm;
  onClick?: (start: Date, end: Date) => void;
  isMobile?: boolean;
  duration?: number;
  hours: number[];
}

const AvailableTermInput = (props: AvailableTermInputComponentProps) => {
  const { term, onClick, isMobile, duration, hours } = props;
  const { i18n } = useTranslation();

  const eventWeekDay = isMobile ? 1 : term.start.getDay() == 0 ? 7 : term.start.getDay();

  const startSpan = Math.floor((term.start.getHours() - hours[0]) * 12 + term.start.getMinutes() / 5) + 2;
  const durationSpan = Math.ceil((term.end.getTime() - term.start.getTime()) / 1000 / 300);

  const endDisplay = duration !== undefined ? new Date(term.start.getTime() + duration * 60000) : new Date(term.end.getTime());

  const onClickListElement = () => onClick && onClick(term.start, term.end);

  const slots = term.slots || [1, 1];

  if (slots[0] === 1 && slots[1] === 1) {
    return (
      <li className="bg-emerald-50 relative mt-px mr-px cursor-pointer ring ring-emerald-50" style={{ gridRow: `${startSpan} / span ${durationSpan}`, gridColumn: `${eventWeekDay}` }
      } onClick={onClickListElement} >
        <div className="bg-emerald-100 hover:bg-emerald-200 active:bg-emerald-300 text-emerald-600 text-xs w-full rounded-lg px-3 py-1.5 h-full">
          {term.start.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'short' })}, <span className="font-medium">{term.start.toLocaleTimeString(i18n.resolvedLanguage, { timeStyle: 'short' })} - {endDisplay.toLocaleTimeString(i18n.resolvedLanguage, { timeStyle: 'short' })}</span>
          <div>{term.description}</div>
        </div>
      </li >
    )
  } else {
    return (
      <li className="bg-sky-50 relative mt-px mr-px cursor-pointer ring ring-sky-50" style={{ gridRow: `${startSpan} / span ${durationSpan}`, gridColumn: `${eventWeekDay}` }
      } onClick={onClickListElement} >
        <div className="bg-sky-100 hover:bg-sky-200 active:bg-sky-300 text-sky-600 text-xs w-full rounded-lg px-3 py-1.5 h-full">
          <div>
            {term.start.toLocaleDateString(i18n.resolvedLanguage, { dateStyle: 'short' })}, <span className="font-medium">{term.start.toLocaleTimeString(i18n.resolvedLanguage, { timeStyle: 'short' })} - {endDisplay.toLocaleTimeString(i18n.resolvedLanguage, { timeStyle: 'short' })}</span>
          </div>
          <div>
            wolne miejsca: <span className="font-medium">{slots[0]}</span> z {slots[1]}
          </div>
          <div>{term.description}</div>
        </div>
      </li >
    )
  }
}

interface DayHeaderComponentProps {
  date: Date;
}

const DayHeader = (props: DayHeaderComponentProps) => {
  const { date } = props;
  const todayDate = new Date();
  const isToday = date.getDate() === todayDate.getDate() && date.getMonth() === todayDate.getMonth() && date.getFullYear() === todayDate.getFullYear();

  return (
    <div className="flex items-center justify-center py-3">
      <WeekDayName weekDay={date.getDay()} format="short" />
      <span
        className={classNames(
          "font-medium ml-3",
          !isToday && (date.getDay() !== 0 ? "text-gray-900" : "text-red-500"),
          isToday && "ml-3 h-8 w-8 flex items-center justify-center rounded-full bg-[--color-primary-600] text-white"
        )}
      >
        {date.getDate()}
      </span>
    </div>
  );
}

interface TimelineColumnRowComponentProps {
  time: Date;
}

const TimelineColumnRow = (props: TimelineColumnRowComponentProps) => {
  const { time } = props;
  const { i18n } = useTranslation();
  return (
    <div>
      <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
        {time.toLocaleTimeString(i18n.resolvedLanguage, { hour: '2-digit', minute: '2-digit' })}
      </div>
    </div>
  );
}

interface EventComponentProps {
  event: CalendarEvent;
  onClick?: (event: CalendarEvent) => void;
  onDelete?: (event: CalendarEvent) => void;
  isMobile?: boolean;
  hours: number[];
}

const Event = (props: EventComponentProps) => {
  const { event, onClick, onDelete, isMobile, hours } = props;
  const { i18n } = useTranslation();

  const eventWeekDay = isMobile ? 1 : event.start.getDay() == 0 ? 7 : event.start.getDay();

  const start = Math.round((event.start.getHours() - hours[0]) * 12 + event.start.getMinutes() / 5 + 2);
  const duration = Math.round((event.end.getTime() - event.start.getTime()) / 1000 / 300);
  const endDisplay = new Date(event.end.getTime() + 1000);

  const onClickListElement = () => onClick && onClick(event);
  const onClickDeleteElement = () => onDelete && onDelete(event);

  const isPast = event.end < new Date();
  const isCurrent = event.start < new Date() && event.end > new Date();

  return (
    <li className={classNames(
      "relative mt-px flex min-h-16 ",
      isPast && "opacity-50",
    )} style={{ gridRow: `${Math.max(start, 1)} / span ${duration}`, gridColumn: `${eventWeekDay}` }}>
      <div
        className={classNames(
          `group absolute inset-1 flex flex-col overflow-hidden rounded-lg bg-${event.colorName}-200 p-2 text-xs leading-5 hover:bg-${event.colorName}-300 `,
          event.active && "cursor-pointer ",
          event.disabled && "cursor-not-allowed ",
          isCurrent && `ring-2 ring-${event.colorName}-400`
        )}
        onClick={onClickListElement}
      >
        <div className={`order-1 font-medium text-${event.colorName}-700`}>{event.label}</div>
        {event.description && <div className={`order-2 font-medium text-${event.colorName}-700`}>{event.description}</div>}
        <div className={`text-${event.colorName}-500 group-hover:text-${event.colorName}-700 text-xs`}>
          <time dateTime="2022-01-12T07:30">{event.start.toLocaleString(i18n.resolvedLanguage, { timeStyle: 'short', dateStyle: 'short' })} - {endDisplay.toLocaleTimeString(i18n.resolvedLanguage, { timeStyle: 'short' })}</time>
        </div>
      </div>
      <div className={`absolute bottom-0 right-0 p-2.5 text-${event.colorName}-700 flex gap-x-3 text-xs`}>
        {event.isPeriodic && <span><FontAwesomeIcon icon={faRepeat} /></span>}
        {event.isConfirmed && <span><FontAwesomeIcon icon={faCheck} /></span>}
        {event.isGroup && <span><FontAwesomeIcon icon={faUsers} /></span>}
      </div>
      {event.deletable && (
        <>
          <button type="button" className="absolute top-0 right-0 p-2.5 text-gray-400 hover:text-rose-700 opacity-50 hover:opacity-80 active:opacity-100" onClick={onClickDeleteElement}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
        </>)}
    </li>
  )
}

interface WeekDayNameComponentProps {
  weekDay: number;
  format?: "long" | "short" | "narrow" | undefined;
}

const WeekDayName = (props: WeekDayNameComponentProps) => {
  const { weekDay, format } = props;
  const { i18n } = useTranslation();
  const date = new Date(Date.UTC(2017, 0, 1 + weekDay));
  return <>{date.toLocaleDateString(i18n.resolvedLanguage, { weekday: format || "long" })}</>;
}

export default CalendarWeekView;