import useForm from "src/hooks/useForm";
import { WizardStepProps } from "./Index";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/pro-duotone-svg-icons";
import ColorSelect, { availableColors } from "src/components/Form/FormColorSelect";
import { useTranslation } from "react-i18next";
import FormSwitch from "src/components/Form/FormSwitch";
import useConfigurationContext from "src/hooks/useConfigurationContext";
import { setConfiguration } from "src/store/configuration/actions";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { Settings, SettingsClient } from "src/api/core/Core";

export default (props: WizardStepProps) => {
  const { nextStep, prevStep } = props;
  const { t } = useTranslation();
  const form = useForm<object>({});
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SettingsClient(apiConfiguration);

  const { state, dispatch } = useConfigurationContext();

  const onSubmit = () => {
    form.setPending(true);
    apiClient.set('layout', undefined, { name: 'layout', value: state.layout } as Settings)
      .catch((ex) => form.catchAnyException(ex, true))
      .then(() => nextStep())
      .finally(() => form.setPending(false));
  }

  const colors = availableColors.map(c => ({ id: c, color: c, label: c }));

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit)}>
      <p className="text-sm text-gray-600">
        Teraz wybierz kolory dla Twojej strony oraz wybierz co ma się znajdować na stronie głównej.
      </p>
      <dl className="my-6 space-y-6 divide-y divide-gray-50 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('panel.settings.layout.primaryColor')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <ColorSelect
              options={colors}
              value={state.layout.primaryColor}
              onChange={(e) => dispatch(setConfiguration('layout.primaryColor', e.target.value))}
            />
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('panel.settings.layout.landing.header')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  placeholder={t('panel.settings.layout.landing.slider.title')}
                  aria-description={t('panel.settings.layout.landing.slider.description')}
                  checked={state.layout.landing.slider}
                  onChange={(e) => dispatch(setConfiguration('layout.landing.slider', e.target.checked))}
                />
              </div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  placeholder={t('panel.settings.layout.landing.newsfeed.title')}
                  aria-description={t('panel.settings.layout.landing.newsfeed.description')}
                  checked={state.layout.landing.newsfeed}
                  onChange={(e) => dispatch(setConfiguration('layout.landing.newsfeed', e.target.checked))}
                />
              </div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  placeholder={t('panel.settings.layout.landing.aboutUs.title')}
                  aria-description={t('panel.settings.layout.landing.aboutUs.description')}
                  checked={state.layout.landing.aboutUs}
                  onChange={(e) => dispatch(setConfiguration('layout.landing.aboutUs', e.target.checked))}
                />
              </div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  placeholder={t('panel.settings.layout.landing.trainingTypes.title')}
                  aria-description={t('panel.settings.layout.landing.trainingTypes.description')}
                  checked={state.layout.landing.trainingTypes}
                  onChange={(e) => dispatch(setConfiguration('layout.landing.trainingTypes', e.target.checked))}
                />
              </div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  placeholder={t('panel.settings.layout.landing.horses.title')}
                  aria-description={t('panel.settings.layout.landing.horses.description')}
                  checked={state.layout.landing.horses}
                  onChange={(e) => dispatch(setConfiguration('layout.landing.horses', e.target.checked))}
                />
              </div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  placeholder={t('panel.settings.layout.landing.instructors.title')}
                  aria-description={t('panel.settings.layout.landing.instructors.description')}
                  checked={state.layout.landing.instructors}
                  onChange={(e) => dispatch(setConfiguration('layout.landing.instructors', e.target.checked))}
                />
              </div>
            </div>
          </dd>
        </div>
      </dl>
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <Button type="button" colorName="gray" className="px-5 py-3" onClick={prevStep}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
          Powrót
        </Button>
        <Button type="submit" colorName="primary" className="px-5 py-3">
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          Przejdź dalej
        </Button>
      </div>
    </form>
  );
};
